import { Popover } from "@mui/material";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import useLocales from "src/hooks/useLocales";

interface Props {
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
  title: string;
}

const TimelinePopover = ({ anchorEl, handlePopoverClose, title }: Props) => {
  const { translate } = useLocales();

  useEffect(() => {
    if (Boolean(anchorEl)) {
      document.body.onwheel = handlePopoverClose;
      document.body.addEventListener("touchstart", handlePopoverClose, false);
    }
    return () => {
      document.body.onwheel = null;
      document.body.removeEventListener(
        "touchstart",
        handlePopoverClose,
        false
      );
    };
  }, [Boolean(anchorEl)]);

  return (
    <Popover
      id="mouse-over-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      disableScrollLock
      marginThreshold={32}
      container={anchorEl?.parentElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        pointerEvents: "none",
      }}
      onClose={handlePopoverClose}
      PaperProps={{
        sx: {
          bgcolor: "background.paper",
          borderRadius: "4px",
          p: 0.5,
          px: 1,
          display: "flex",
          alignItems: "center",
          overflowWrap: "break-word",
          // inlineSize: "450px",
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          color: "text.greyLight",
          fontSize: "10px",
        }}
        variant="subtitle2"
      >
        {translate(title)}
      </Typography>
    </Popover>
  );
};
export default TimelinePopover;
