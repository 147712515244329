import { useState, useEffect, useCallback, Dispatch } from "react";
import { SetStateAction } from "react";
import { Box, Table, TableBody, Container } from "@mui/material";
import { TableContainer, Dialog, DialogTitle, Tabs } from "@mui/material";
import { DialogContent, SelectChangeEvent, Tab } from "@mui/material";
import { DialogContentText, DialogActions, Button } from "@mui/material";
import useSettings from "src/hooks/useSettings";
import useInterval from "src/hooks/useInterval";
import useLocales from "src/hooks/useLocales";
import Page from "src/components/Page";
import { TableNoData, TableSkeleton } from "src/components/table";
import FinanceTableRow from "./TableRow";
import { API_GATEWAY } from "src/constants/settings";
import { GetAsync, PatchAsync } from "src/common/httpRequests";
import { useSnackbar } from "notistack";
import TableHeadCustom from "./tableHeadeCustom";
import MyTablePagination from "src/components/pagination/TablePagination";
import useApprovalFilter from "./useApprovalFilter";
import SearchFilters from "./Filters";
import { WorkflowApprovalDocuments } from "../type";

export interface ApprovalDocuments {
  workflowId: string;
  setDocument: Dispatch<SetStateAction<any>>;
}

export default function InvoiceList({
  workflowId,
  setDocument,
}: ApprovalDocuments) {
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const { filterState, setFilterState } = useApprovalFilter({
    record: 10,
    page: 1,
  });
  const [tabs, setTabs] = useState<string[]>(["all"]);
  const [tableData, setTableData] = useState<WorkflowApprovalDocuments[]>([]);
  const [loaderSkeleton, setLoaderSkeleton] = useState(true);
  const [noOfItems, setNoOfItems] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const [alertConfig, setAlertConfig] = useState({
    alert: "",
    id: "",
    workflowId: "",
    documentId: "",
  });
  const { translate } = useLocales();
  const [dialogLoading, setDialogLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoaderSkeleton(true);
    let params = getParams(filterState);
    await GetAsync(
      `${API_GATEWAY}/api/workflowservice/workflowapprovals/documents`,
      true,
      undefined,
      {
        params: params,
      }
    )
      .then((res: any) => {
        setTableData(res?.data?.userWorkflowApprovalDocuments);
        setNoOfItems(res?.data?.filteredRecordsCount);
        setLoaderSkeleton(false);
        if (tabs.length === 1) {
          setTabs([...tabs, ...res?.data?.documentClasses]);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoaderSkeleton(false);
      });
  }, [tabs]);

  const getParams = (values) => {
    let params = {
      documentName: values?.documentName || undefined,
      documentStatus: values?.documentStatus || undefined,
      documentClass:
        values.documentClass === "all"
          ? undefined
          : values.documentClass || undefined,
      fromDate: values?.fromDate || undefined,
      toDate: values?.toDate || undefined,
      workflowId: workflowId || undefined,
      page: values?.page || undefined,
      record: values?.record || undefined,
      status: values?.approvalStatus || undefined,
    };
    return params;
  };

  const renderDocument = (values) => {
    let params = getParams(values);
    return GetAsync(
      `${API_GATEWAY}/api/workflowservice/workflowapprovals/documents`,
      true,
      undefined,
      {
        params: params,
      }
    ).then((res: any) => {
      setTableData(res?.data?.userWorkflowApprovalDocuments ?? []);
      setNoOfItems(res?.data?.filteredRecordsCount ?? 0);
    });
  };

  useInterval(
    () => {
      if (filterState.page > 0) {
        renderDocument(filterState);
      }
    },
    10000,
    [filterState]
  );

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      fetchData();
    } else {
      setLoaderSkeleton(true);
      renderDocument(filterState).finally(() => {
        setLoaderSkeleton(false);
      });
    }
  }, [filterState]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (
    alert: string,
    id: string,
    workflowId: string,
    documentId: string
  ) => {
    setAlertConfig({
      alert: alert,
      id: id,
      workflowId: workflowId,
      documentId: documentId,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setAlertConfig({ alert: "", id: "", workflowId: "", documentId: "" });
    setOpen(false);
  };

  const handleApprove = () => {
    setDialogLoading(true);
    if (alertConfig.alert === "approve") {
      PatchAsync(
        `${API_GATEWAY}/api/workflowservice/workflowapprovals/approved`,
        {
          id: alertConfig.id,
          workflowId: alertConfig.workflowId,
          documentId: alertConfig.documentId,
        }
      )
        .then((res: any) => {
          if (res?.status >= 200 && res?.status <= 299) {
            enqueueSnackbar(translate(`successfully_approved`), {
              variant: "success",
            });
            renderDocument(filterState).finally(() => {});
          } else {
            enqueueSnackbar(`${res?.data?.Detail}`, { variant: "error" });
          }
        })
        .catch((err: any) => {
          enqueueSnackbar(`${err?.data?.Detail}`, { variant: "error" });
          console.log("Error-->", err);
        })
        .finally(() => {
          setAlertConfig({ alert: "", id: "", workflowId: "", documentId: "" });
          setOpen(false);
          setDialogLoading(false);
        });
    } else if (alertConfig.alert === "reject") {
      PatchAsync(
        `${API_GATEWAY}/api/workflowservice/workflowapprovals/rejected`,
        {
          id: alertConfig.id,
        }
      )
        .then((res: any) => {
          if (res?.status >= 200 && res?.status <= 299) {
            enqueueSnackbar(translate(`successfully_rejected`), {
              variant: "success",
            });
            renderDocument(filterState).finally(() => {});
          } else {
            enqueueSnackbar(`${res?.data?.Detail}`, { variant: "error" });
          }
        })
        .catch((err: any) => {
          enqueueSnackbar(`${err?.data?.Detail}`, { variant: "error" });
          console.log("Error-->", err);
        })
        .finally(() => {
          setAlertConfig({ alert: "", id: "", workflowId: "", documentId: "" });
          setOpen(false);
          setDialogLoading(false);
        });
    }
  };

  const optionsStatus = [
    { enum: "", value: `${translate(`finance_screen.all`)}` },
    { enum: "1", value: `${translate(`status.processed`)}` },
    { enum: "4", value: `${translate(`status.need_review`)}` },
    { enum: "5", value: `${translate(`status.reviewed`)}` },
    { enum: "3", value: `${translate(`status.error`)}` },
    { enum: "6", value: `${translate(`status.duplicate`)}` },
    { enum: "2", value: `${translate(`status.failed`)}` },
  ];

  const SKELETON: string[] = ["", "", "", "", "", "", ""];

  const optionsApprovalStatus = [
    { enum: "", value: `${translate(`finance_screen.all`)}` },
    { enum: "0", value: `${translate(`pending`)}` },
    { enum: "1", value: `${translate(`approved`)}` },
    { enum: "2", value: `${translate(`rejected`)}` },
  ];

  return (
    <Page title="Processed Documents">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {alertConfig.alert === "approve"
                ? translate("approval_screen.alertApproveTitle")
                : translate("approval_screen.alertRejectTitle")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {alertConfig.alert === "approve"
                  ? translate("approval_screen.alertApproveMessage")
                  : translate("approval_screen.alertRejectMessage")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                size="small"
                disabled={dialogLoading}
              >
                {translate("approval_screen.no")}
              </Button>
              <Button
                onClick={handleApprove}
                autoFocus
                disabled={dialogLoading}
                variant="contained"
                size="small"
              >
                {translate("approval_screen.yes")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterState?.documentClass || "all"}
          onChange={(e, value) => {
            setFilterState({ ...filterState, documentClass: value, page: 1 });
          }}
        >
          {tabs.map((tab) => (
            <Tab disableRipple key={tab} value={tab} label={translate(tab)} />
          ))}
        </Tabs>
        <SearchFilters
          {...{
            loader: loaderSkeleton,
            filterState,
            setFilterState,
            optionsStatus,
            optionsApprovalStatus,
          }}
        />
        <TableContainer sx={{ minWidth: 800, position: "relative" }}>
          <Table size={"small"}>
            <TableHeadCustom />
            <TableBody>
              {loaderSkeleton
                ? SKELETON.map((_, index) => <TableSkeleton key={index} />)
                : tableData?.map((row: WorkflowApprovalDocuments) => (
                    <FinanceTableRow
                      key={row?.id}
                      row={row}
                      loader={loaderSkeleton}
                      handleClickOpen={handleClickOpen}
                      setDocument={setDocument}
                    />
                  ))}
              <TableNoData
                isNotFound={tableData?.length === 0 && !loaderSkeleton}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ position: "relative" }}>
          <MyTablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={noOfItems}
            rowsPerPage={filterState?.record || 10}
            page={filterState?.page - 1 || 0}
            onPageChange={(event, page) => {
              setFilterState({ ...filterState, page: page + 1 });
            }}
            onRowsPerPageChange={(event: SelectChangeEvent<any>) => {
              setFilterState({
                ...filterState,
                page: 1,
                record: parseInt(event.target.value, 10),
              });
            }}
          />
        </Box>
      </Container>
    </Page>
  );
}
