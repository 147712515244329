import { useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
// hooks
import useSettings from "../../hooks/useSettings";
import useResponsive from "../../hooks/useResponsive";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// config
import { HEADER, NAVBAR } from "../../config";
import DashboardHeader from "./header";
import NavbarVertical from "./navbar/NavbarVertical";
import NavbarHorizontal from "./navbar/NavbarHorizontal";
import Footer from "./Footer";

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  paddingBottom: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 10,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT - 30,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { themeLayout } = useSettings();
  const isDesktop = useResponsive("up", "lg");
  const [open, setOpen] = useState(false);
  const verticalLayout = themeLayout === "vertical";

  if (verticalLayout) {
    return (
      <>
        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
        )}
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
          <Footer />
        </Box>
      </>
    );
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <DashboardHeader
        isCollapse={isCollapse}
        onOpenSidebar={() => setOpen(true)}
      />
      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle collapseClick={collapseClick}>
        <Box sx={{ pb: 2 }}>
          <Outlet />
        </Box>
        <Footer />
      </MainStyle>
    </Box>
  );
}
