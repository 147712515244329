import { Box, MenuItem, Select, Typography } from "@mui/material";
import { TablePaginationProps } from "@mui/material";
import { NewTextFormField } from "../form/TextField";
import { useTheme } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { SelectChangeEvent } from "@mui/material/Select";
import Pagination from "./Pagination";

type MyTablePaginationPropsBase = Omit<
  TablePaginationProps,
  "onPageChange" | "onRowsPerPageChange"
> & {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: any, newPage: number) => void;
  disableSelectRowsPerPage?: boolean;
  border?: boolean;
};

type MyTablePaginationPropsWithRowsPerPage = MyTablePaginationPropsBase & {
  disableSelectRowsPerPage?: false;
  onRowsPerPageChange: (event: SelectChangeEvent<HTMLInputElement>) => void;
};

type MyTablePaginationPropsWithoutRowsPerPage = MyTablePaginationPropsBase & {
  disableSelectRowsPerPage: true;
  onRowsPerPageChange?: never;
};

type MyTablePaginationProps =
  | MyTablePaginationPropsWithRowsPerPage
  | MyTablePaginationPropsWithoutRowsPerPage;

const MyTablePagination = ({ ...props }: MyTablePaginationProps) => {
  const {
    count,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    disableSelectRowsPerPage,
    border = true,
    rowsPerPageOptions,
  } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(event, newPage);
  };
  const theme = useTheme();
  const { translate } = useLocales();
  console.log("rowsPerPage", rowsPerPage);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexWrap: "wrap",
        ...(border
          ? {
              padding: 1,
              borderTop: "none",
              border: "1px solid #E0E0E0",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }
          : {}),
        justifyContent: "space-between",
      }}
    >
      {disableSelectRowsPerPage ? (
        <Box />
      ) : (
        <Box
          sx={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "8px",
            p: 1,
          }}
        >
          <Typography variant="subtitle2">
            {translate("Entries per page:")}
          </Typography>
          <Select
            id="customized-textbox"
            displayEmpty
            value={
              (rowsPerPage?.toString() || "") as unknown as HTMLInputElement
            }
            input={<NewTextFormField />}
            sx={{
              "& .MuiInputBase-input": {
                backgroundColor: "background.paper",
                padding: "7px 26px 7px 10px",
                height: "32px",
                display: "flex",
                alignItems: "center",
              },
            }}
            onChange={onRowsPerPageChange}
          >
            {rowsPerPageOptions?.map((item, index) => (
              <MenuItem key={index} value={`${item}`}>
                {item}
              </MenuItem>
            ))}
            {/* <MenuItem value="10">10</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem> */}
          </Select>
        </Box>
      )}
      <Pagination
        page={page}
        record={rowsPerPage}
        count={count}
        onChangePage={handleChangePage}
      />
    </Box>
  );
};

export default MyTablePagination;
