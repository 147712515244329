import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";
import { Box, MenuItem, Select } from "@mui/material";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
export const BootstrapFormInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "4px",
    backgroundColor: theme.palette.background.paper,
    padding: "2px 5px 2px 5px",
    // height: "20px",
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    border: "1px solid #528ACC",
    boxShadow: `0 0 0 0.01rem ${theme.palette.secondary.main}`,
    color: "#657482",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Public Sans"].join(","),
    lineHeight: "16px",
    fontSize: "12px",
    "&:focus": {
      border: "1px solid #528ACC",
      boxShadow: `0 0 0 0.01rem ${theme.palette.secondary.main}`,
      borderRadius: "4px",
    },
  },
}));
export const NewTextFormField = React.forwardRef(
  ({ sx = {}, helperText, InputLabelProps, ...props }: any, ref) => {
    const theme = useTheme();
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          p: 0,
          gap: "4px",
          // width: "324px",
          //   height: "32px",
        }}
      >
        {props.label && (
          <InputLabel
            htmlFor="demo-customized-textbox"
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              fontStyle: "normal",
              lineHeight: "12px",
              height: "12px",
              fontFamily: ["Inter", "Public Sans"].join(","),
              flexGrow: 1,
              flex: "none",
              order: 0,
              color: theme.palette.action.active,
              ...sx,
              ...InputLabelProps,
            }}
          >
            {props.label}
          </InputLabel>
        )}
        <BootstrapFormInput
          sx={{
            "& .MuiInputBase-input": {
              ...(props.error && {
                backgroundColor: theme.palette.error.light,
                borderColor: theme.palette.error.dark,
                color: theme.palette.error.dark,
                "&::placeholder": {
                  opacity: 1,
                  color: theme.palette.error.dark,
                },
                "&:focus": {
                  borderColor: theme.palette.error.dark,
                  boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
                },
                ...sx,
              }),
              ...sx,
            },
          }}
          id="demo-customized-textbox"
          fullWidth
          {...props}
          ref={ref}
        />
        {props.error && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "12px",
              fontFamily: ["Inter", "Public Sans"].join(","),
              fontSize: "12px",
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "12px",
              color: theme.palette.error.dark,
            }}
          >
            {helperText}
          </Box>
        )}
      </Box>
    );
  }
);

export const NewSelectFormField = ({
  placeholder,
  onChange,
  sx,
  onBlur,
  inputProps,
  label,
  options,
  required,
  error,
  multiple = false,
  disabled = false,
  ...props
}: any) => {
  const theme = useTheme();

  return (
    <>
      <Select
        error={!!error}
        inputProps={inputProps}
        displayEmpty
        IconComponent={() => {
          return (
            <ChevronDown
              height={18}
              width={18}
              customColor={
                error ? theme.palette.error.dark : theme.palette.grey[600]
              }
              sx={{
                position: "absolute",
                right: "6px",
                top: "calc(50% - 8px)",
                pointerEvents: "none",
                color: "#657482",
              }}
            />
          );
        }}
        labelId={props.name}
        input={<NewTextFormField placeholder={placeholder} label={label} />}
        multiple={multiple}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        {...props}
      >
        {placeholder ? <MenuItem value="">{placeholder}</MenuItem> : null}
        {options
          .filter((o) => !o.disabled)
          .map((o) => {
            return (
              <MenuItem
                sx={{
                  fontSize: 12,
                }}
                key={o.value}
                value={o.value}
              >
                {o.label}
              </MenuItem>
            );
          })}
      </Select>
      {!!error && (
        <span
          style={{
            color: "#CE0D09",
            fontSize: "12px",
            marginTop: "3px",
          }}
          className="form-label"
        >
          {error}
        </span>
      )}
    </>
  );
};
