import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Typography,
  AlertTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PATH_AUTH } from "../../../routes/paths";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import useLocales from "../../../hooks/useLocales";
import { MixpanelFunction } from "../../../../src/mixpanel/mixpanelFunction";
import { MixpanelUnsuccess } from "../../../../src/mixpanel/mixpanelUnsuccess";
import { useDispatch } from "../../../redux/store";
import { setExternalIdentities } from "src/redux/slices/externalIdentities";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { textStyle } from "../../../theme/style";
import { useTheme } from "@mui/material/styles";
import { API_GATEWAY, IS_AGENT } from "src/constants/settings";
import { Divider } from "@mui/material";
import { GetAsync } from "src/common/httpRequests";
import { EXTERNAL_IDENTITY_LOGIN } from "src/constants/settings";
import LoadingScreen from "src/components/LoadingScreen";
import { bootIntercomm } from "src/intercom";
import EyeOpenIcon from "src/assets/icon/eye/On";
import EyeCloseIcon from "src/assets/icon/eye/Off";

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const theme = useTheme();
  const { translate } = useLocales();
  const { mode } = theme?.palette;
  const dispatch = useDispatch();
  const { Login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [isExternalIdLogin, setIsExternalIdLogin] = useState<boolean>(false);
  const [externalLoginIdentities, setExternalLoginIdentities] = useState<any>(
    []
  );
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate("Email must be a valid email address"))
      .when("afterSubmit", {
        is: (afterSubmit: string) => !!afterSubmit,
        then: Yup.string().required(translate("Email is invalid")),
      })
      .required(translate("Email is required")),
    password: Yup.string()
      .when("afterSubmit", {
        is: (afterSubmit: string) => !!afterSubmit,
        then: Yup.string().required(translate("Password is invalid")),
      })
      .required(translate("Password is required")),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    let { email, password } = data;
    email = email.toLowerCase();
    try {
      const resp = await Login(email, password);
      bootIntercomm(email, email);
      const COMPANY_ID = localStorage.getItem("company_id");
      const PROFILE = JSON.parse(localStorage.getItem("profile") || "{}");

      //Calling UserService API to get CustomLogo
      const response = await GetAsync(
        `${API_GATEWAY}/api/userservice/Organization/configuration`
      );
      // If the value of custom logo is not null or empty string then save in localStorage concatenating it with UserService API URL
      if (response.data.organizationConfiguration.customLogoWithBaseURI) {
        localStorage.setItem(
          "customLogo",
          response?.data?.organizationConfiguration?.customLogoWithBaseURI
        );
      }
      MixpanelFunction({
        eventName: "Login",
        eventSource: "Authentication",
        user: "Login",
        userEmail: email,
        status: "successful",
        signInMethod: "Email",
        organizationId: COMPANY_ID,
        userProfile: PROFILE,
        impersonateMode: false,
      });
    } catch (error) {
      MixpanelUnsuccess({
        eventName: "Login",
        eventSource: "Authentication",
        Useremail: email,
        status: "unsuccessful",
      });

      if (isMountedRef.current) {
        setError("afterSubmit", {
          ...error,
          message: error.Detail ? error.Detail : error,
        });
        setError("email", {
          ...error,
          message: translate("Email or password is invalid"),
        });
        setError("password", {
          ...error,
          message: translate("Email or password is invalid"),
        });
      }
    }
  };

  useEffect(() => {
    let isMounted: boolean = true;
    const fetchExternalIdentity = async () => {
      const response = await GetAsync(
        `${API_GATEWAY}/api/userservice/ExternalIdentity`
      );
      try {
        dispatch(setExternalIdentities(response.data.externalIdentities));
        if (isMounted) {
          setExternalLoginIdentities(response.data.externalIdentities);
        }
        setLoader(false);
      } catch (e) {
        console.log(e.message);
      }
    };
    fetchExternalIdentity();
    return () => {
      isMounted = false;
    };
  }, []);

  const loginRedirect = (url: string, id: string, key: string): void => {
    setIsExternalIdLogin(true);
    localStorage.setItem("externalProviderId", id);
    localStorage.setItem("signUpMethod", key);
    window.location.replace(`${url}`);
    setIsExternalIdLogin(false);
  };

  return (
    <>
      {loader ? (
        <LoadingScreen />
      ) : (
        <>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              {(!!errors.email || !!errors.password || errors.afterSubmit) && (
                <Alert
                  severity="error"
                  sx={{
                    border: `1px solid ${theme.palette.error.dark}`,
                    color: theme.palette.error.dark,
                    backgroundColor: "#ffefef",
                  }}
                >
                  <AlertTitle>{translate("Information")}</AlertTitle>

                  {errors.afterSubmit
                    ? translate(errors.afterSubmit.message)
                    : translate(
                        "Make sure all the required fields are correctly filled in"
                      )}
                </Alert>
              )}
              <div>
                <RHFTextField
                  name="email"
                  label={translate("authentication_screen.email_address")}
                  placeholder={translate("admin@mycompany.com")}
                />
              </div>
              <div>
                <RHFTextField
                  name="password"
                  size="small"
                  label={translate("authentication_screen.password")}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          marginLeft: -5,
                        }}
                      >
                        {showPassword ? (
                          <IconButton
                            sx={{ zIndex: 1000 }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <EyeOpenIcon
                              customColor={
                                !!errors.password
                                  ? theme.palette.error.dark
                                  : theme.palette.primary.main
                              }
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            sx={{ zIndex: 1000 }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <EyeCloseIcon
                              customColor={
                                !!errors.password
                                  ? theme.palette.error.dark
                                  : theme.palette.primary.main
                              }
                            />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <RHFCheckbox
                name="remember"
                label={translate("authentication_screen.remember_me")}
              />

              {IS_AGENT ? null : (
                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  to={PATH_AUTH.resetPassword}
                  sx={{ color: "#3B74B7" }}
                >
                  {translate("authentication_screen.forgot_password")}
                </Link>
              )}
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={isExternalIdLogin}
            >
              <span style={{ fontSize: "0.9375rem" }}>
                {translate("authentication_screen.login_btn")}
              </span>
            </LoadingButton>
            {EXTERNAL_IDENTITY_LOGIN && (
              <div>
                <Divider sx={{ marginY: 3 }}>
                  {translate(`authentication_screen.or_login_with`)}
                </Divider>
                <div>
                  {externalLoginIdentities?.map((item, index: number) => {
                    return (
                      <LoadingButton
                        key={index}
                        className="external-login"
                        sx={{
                          marginBottom: "20px",
                          backgroundColor: theme.palette.secondary.dark,
                        }}
                        fullWidth
                        onClick={() => {
                          loginRedirect(item.url, item.id, item.key);
                        }}
                        size="large"
                        variant="contained"
                        loading={isExternalIdLogin}
                        disabled={isSubmitting}
                      >
                        <span
                          style={{ textAlign: "center", fontSize: "0.9375rem" }}
                        >
                          {translate(
                            `authentication_screen.login_with_${item.key}`
                          )}
                        </span>
                      </LoadingButton>
                    );
                  })}
                </div>
              </div>
            )}
          </FormProvider>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
}
