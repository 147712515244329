import { TableRow, TableCell, TableHead, Typography } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import FileIcon from "src/assets/icon/file";

export default function TableHeadCustom() {
  const { translate } = useLocales();
  const TABLE_HEAD = [
    {
      id: "invoiceNumber",
      label: translate(`Document Name`),
      align: "left",
      icon: (
        <FileIcon
          sx={{
            mr: "7px",
          }}
        />
      ),
    },
    {
      id: "workflowName",
      label: translate(`Workflow Name`),
      align: "left",
    },
    {
      id: "date",
      label: translate(`Processed on`),
      align: "left",
    },
    {
      id: "status",
      label: translate(`Status in workflow`),
    },
    {
      id: "review",
      label: translate(`Action`),
      align: "left",
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {TABLE_HEAD.map((headCell, index) => (
          <TableCell key={headCell.id} align={"left"}>
            <Typography
              variant="subtitle2"
              sx={{
                display: "flex",
                fontWeight: "bold",
                alignItems: "center",
              }}
            >
              {headCell.icon}
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
