// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_CONFIGURATION = "/configuration";
const ROOTS_CATALOG = "/catalog";
const ROOTS_USERONBOARD = "/reg";
const ROOTS_ACCOUNT = "/user";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
  preRegister: path(ROOTS_AUTH, "/pre-register"),
  preVerify: path(ROOTS_AUTH, "/pre-verify"),
  newRegister: path(ROOTS_AUTH, "/new-register"),
  impersonate: path(ROOTS_AUTH, "/impersonate"),
};

export const PATH_USERONBOARD = {
  root: ROOTS_USERONBOARD,
  useronboard: path(ROOTS_USERONBOARD, "/useronboard"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
  verification: "/verification",
};
export const PATH_CONFIGURATION = {
  root: ROOTS_CONFIGURATION,
  general: {
    documentConfiguration: path(ROOTS_CONFIGURATION, "/document-configuration"),
    documentConfigurationV2: path(
      ROOTS_CONFIGURATION,
      "/document-configuration-v2"
    ),
    inputCSVMapping: path(ROOTS_CONFIGURATION, "/input-csv-mapping"),
    outputCSVMapping: path(ROOTS_CONFIGURATION, "/output-csv-mapping"),
    expectedValuesConfiguration: path(
      ROOTS_CONFIGURATION,
      "/predefined-values-configuration"
    ),
    erpSyncConfiguration: path(ROOTS_CONFIGURATION, "/erp-sync-configuration"),
  },
};

export const PATH_CATALOG = {};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
    documents: {
      root: path(ROOTS_DASHBOARD, "/documents"),
      general: {
        review: path(ROOTS_DASHBOARD, "/documents/review"),
        mailInbox: path(ROOTS_DASHBOARD, "/documents/mailinbox"),
        createDocument: path(ROOTS_DASHBOARD, "/documents/createdocument"),
      },
    },
    discrepancy: path(ROOTS_DASHBOARD, "/view-discrepancy"),
    newIntegeration: path(ROOTS_DASHBOARD, "/newintegration"),
    integration: path(ROOTS_DASHBOARD, "/integration"),
    configuration: path(ROOTS_DASHBOARD, "/configuration"),
    users: path(ROOTS_DASHBOARD, "/users"),
    comingSoon: path(ROOTS_DASHBOARD, "/comingSoon"),
    workflow: path(ROOTS_DASHBOARD, "/workflow"),
    approval: path(ROOTS_DASHBOARD, "/approved-documents"),
    dataAnalytics: path(ROOTS_DASHBOARD, "/dataAnalytics"),
    resourceSaved: path(ROOTS_DASHBOARD, "/resourceSaved"),
    upgrade: path(ROOTS_DASHBOARD, "/upgrade"),
    feedback: path(ROOTS_DASHBOARD, "/feedback"),
    adminUpdateModels: path(ROOTS_DASHBOARD, "/adminUpdateModels"),
    checkout: path(ROOTS_DASHBOARD, "/checkout"),
    stripe: path(ROOTS_DASHBOARD, "/stripe"),
    newStripe: path(ROOTS_DASHBOARD, "/newStripe"),
    agentReview: path(ROOTS_DASHBOARD, "/agentreview"),
    adminUpgradePlan: path(ROOTS_DASHBOARD, "/adminUpgradePlan"),
    adminReport: path(ROOTS_DASHBOARD, "/adminReport"),
    release: path(ROOTS_DASHBOARD, "/release"),
    suppliers: path(ROOTS_DASHBOARD, "/suppliers"),
    payments: path(ROOTS_DASHBOARD, "/payments"),
    catalog: {
      root: ROOTS_CATALOG,
      general: {
        items: path(ROOTS_DASHBOARD, "/catalog/items"),
      },
    },
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    view: (name: string) =>
      path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) =>
      path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    new: path(ROOTS_DASHBOARD, "/invoice/new"),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"
    ),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    new: path(ROOTS_DASHBOARD, "/blog/new"),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(
      ROOTS_DASHBOARD,
      "/blog/post/apply-these-7-secret-techniques-to-improve-event"
    ),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
