import { FOLDERTYPE, API_GATEWAY } from "../constants/settings";
import { GetAsync, PostAsync } from "./httpRequests";
import CommonService from "./commonService";
import { fDate } from "src/utils/formatTime";
export default class UploadsInfoService {
  constructor() {
    this.commonService = new CommonService();
  }
  async ListCustomerInvoices() {
    return await this.ListFilesInside(FOLDERTYPE.CUSTOMERINVOICES);
  }
  async ListSupplierInvoices() {
    return await this.ListFilesInside(FOLDERTYPE.SUPPLIERINVOICES);
  }
  async ListBankStatements() {
    return await this.ListFilesInside(FOLDERTYPE.BANKSTATEMENTS);
  }
  async ListThirdPartyPayouts() {
    return await this.ListFilesInside(FOLDERTYPE.THIRDPARTYPAYOUTS);
  }
  async ListProccessSupplierInvoices(
    pageNo = 1,
    pageSize = 10,
    filterInvoiceStatus = "All",
    selectedDocumentsName,
    fileClass,
    startDate,
    endDate
  ) {
    return await this.ListMatched(
      FOLDERTYPE.SUPPLIERINVOICES,
      pageNo,
      pageSize,
      filterInvoiceStatus,
      selectedDocumentsName,
      fileClass,
      startDate,
      endDate
    );
  }

  async listProcessedDocuments(
    page = 1,
    record = 25,
    documentStatus,
    documentApporvalStatus,
    isERPIntegrated,
    includeDeleted,
    documentName,
    documentClass,
    startDate,
    endDate,
    fields,
    cancelToken
  ) {
    return await this.listMatchedDocuments(
      documentName,
      documentStatus,
      documentApporvalStatus,
      isERPIntegrated,
      includeDeleted,
      documentClass,
      startDate,
      endDate,
      fields,
      page,
      record,
      cancelToken
    );
  }

  //delegate responsible for API CALL
  ListFilesInside(folderType) {
    var formData = new FormData();
    formData.append("folderName", folderType);
    return PostAsync(
      `${API_GATEWAY}/api/webapi/FilesUpload/ListInvoices`,
      formData,
      true
    );
  }

  async listMatchedDocuments(
    documentName,
    documentStatus,
    documentApporvalStatus,
    isERPIntegrated,
    includeDeleted,
    documentClass,
    startDate,
    endDate,
    fields,
    page,
    record,
    cancelToken
  ) {
    let fields_ = {};
    fields.forEach((field, i) => {
      fields_[`fields[${i}].fieldName`] = field.fieldName;
      fields_[`fields[${i}].value`] = field.value;
    });

    const listProcessedDocuments = await GetAsync(
      `${API_GATEWAY}/api/documentservice/Document`,
      true,
      cancelToken,
      {
        params: {
          documentName: documentName || undefined,
          documentStatus: documentStatus || undefined,
          documentClass: documentClass || undefined,
          fromDate: startDate ? fDate(startDate, "yyyy-MM-dd") : undefined,
          toDate: endDate ? fDate(endDate, "yyyy-MM-dd") : undefined,
          page: page,
          record: record,
          approvalStatus: documentApporvalStatus || undefined,
          isIntegrated: isERPIntegrated || undefined,
          includeDeleted: includeDeleted || undefined,
          ...(fields_ ?? {}),
        },
      }
    );
    try {
      if (listProcessedDocuments?.status === 200) {
        return listProcessedDocuments;
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  //delegate responsible for API CALL
  async ListMatched(
    folderType,
    pageNo,
    pageSize,
    filterInvoiceStatus,
    selectedDocumentsName,
    fileClass,
    startDate,
    endDate
  ) {
    var formData = new FormData();
    // formData.append("folderName", folderType);
    const listProcessedInvoicesResponse = await PostAsync(
      `${API_GATEWAY}/api/webapi/FilesUpload/ListProcessedInvoices?page=${pageNo}&pageSize=${pageSize}&folderName=${folderType}&documentStatus=${filterInvoiceStatus}&fileName=${selectedDocumentsName}&fileClass=${fileClass}&fromDate=${
        startDate > 0 ? fDate(startDate, "yyyy-MM-dd") : ""
      }&toDate=${endDate > 0 ? fDate(endDate, "yyyy-MM-dd") : ""}`,
      formData,
      true
    );
    // await this.commonService.getBlobContainerSize();
    return listProcessedInvoicesResponse;
  }
}
