import { useEffect, useState } from "react";
import { GetAsync, PutAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import useInterval from "src/hooks/useInterval";

type Notification = {
  id: string;
  message: string;
  notificationType: 0 | 1 | 2 | 3 | 4;
  count: number;
  key:
    | "discrepancies"
    | "document_pending_approval"
    | "document_limit_exceed"
    | "new_successfull_extraction";
};
const useNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const getNotifications = async () => {
    const response = await GetAsync(
      `${API_GATEWAY}/api/notificationservice/notifications`
    );
    const notifications = response.data.notifications;
    return notifications;
  };

  const fetchNotifications = async () => {
    const notifications = await getNotifications();
    let noti_ = notifications.map((noti) => {
      return {
        ...noti,
        message: notificationMessageMap[noti.notificationType],
      };
    });
    setNotifications(noti_);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useInterval(
    () => {
      fetchNotifications();
    },
    20000,
    []
  );

  return {
    notifications,
    setNotifications,
  };
};

export default useNotifications;

export const readNotification = async (id: string) => {
  return PutAsync(`${API_GATEWAY}/api/notificationservice/notifications`, {
    ids: [id],
  });
};

const notificationMessageMap = {
  0: "new successfull extraction",
  1: "documents pending approval",
  2: "discrepancies_",
  3: "document limit exceed",
  4: "Connector failed to connect. Please try again with valid credentials.",
};
