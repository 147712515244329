import { IconButton, useTheme } from "@mui/material";
import { TableCell, TableRow } from "@mui/material";
import { Fragment, useState } from "react";
import useLocales from "src/hooks/useLocales";
import DeleteDialog from "./DeleteDialog";
import DeleteIcon from "src/assets/icon/delete";

export default function Headings({
  headers,
  handleDeleteColumn,
  enableDeleteColumn,
  setEnableDeleteTable,
  enableDeleteTable,
  checkDefaultHeaders,
}: {
  headers: Array<any>;
  handleDeleteColumn: any;
  enableDeleteColumn: boolean;
  setEnableDeleteTable: any;
  enableDeleteTable: boolean;
  checkDefaultHeaders: any;
}) {
  const { translate } = useLocales();
  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") === -1) {
      return result;
    }
    return title;
  };
  const theme = useTheme();
  const [name, setName] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  return (
    <TableRow>
      <DeleteDialog
        name={name}
        open={open}
        enableDeleteTable={enableDeleteTable}
        handleClose={() => {
          setName("");
          setOpen(false);
        }}
        deleteRow={handleDeleteColumn}
      />
      <TableCell align="left" sx={{ fontWeight: "bold" }}>
        {translate("Line")}
      </TableCell>
      {headers
        .sort((a: any, b: any) => {
          if (
            getTranslatedFields(a).toLowerCase() >
            getTranslatedFields(b).toLowerCase()
          ) {
            return 1;
          }
          if (
            getTranslatedFields(a).toLowerCase() <
            getTranslatedFields(b).toLowerCase()
          ) {
            return -1;
          }
          return 0;
        })
        .map((header, index) => (
          <Fragment key={"header-" + header + "-" + index}>
            <TableCell align="left" sx={{ fontWeight: "bold" }}>
              {getTranslatedFields(header)}
            </TableCell>
            {enableDeleteColumn && (
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                <IconButton
                  disabled={checkDefaultHeaders(header)}
                  size="small"
                  sx={{
                    height: "27px",
                    width: "27px",
                    color: "grey.400",
                    bgcolor: "background.paper",
                    cursor: "pointer",
                    "&:hover": { color: "black" },
                  }}
                  onClick={(e) => {
                    if (headers.length === 1) {
                      setEnableDeleteTable(true);
                    }
                    setName(header);
                    setOpen(true);
                  }}
                >
                  <DeleteIcon
                    height={18}
                    width={18}
                    customColor={
                      checkDefaultHeaders(header)
                        ? theme.palette.grey[400]
                        : theme.palette.primary.main
                    }
                  />
                </IconButton>
              </TableCell>
            )}
          </Fragment>
        ))}
      <TableCell align="left">{translate("Action")}</TableCell>
    </TableRow>
  );
}
