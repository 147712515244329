import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
  Typography,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import { RightArrowIcon } from "src/assets/icon/arrow/rightArrow";
import InfoIcon from "src/assets/icon/info";
import { lighten } from "@mui/material/styles";
import { readNotification } from "./useNotifications";
import useLocales from "src/hooks/useLocales";
import Multiply from "src/assets/icon/multiply/Multiply";
import NotificationReadIcon from "src/assets/icon/notification";

type Props = {
  notifications: TNotification[];
  displayALl?: boolean;
  setNotifications: any;
};

type TNotification = {
  id: string;
  message: string;
  notificationType: 0 | 1 | 2 | 3 | 4;
  count: number;
};

const NotificationAlertBox = ({
  notifications,
  setNotifications,
  displayALl,
}: Props) => {
  const theme = useTheme();
  const { translate } = useLocales();

  if (notifications.length === 0) {
    return <></>;
  }
  return (
    <>
      {notifications.map((notification) => (
        <Tooltip
          key={notification.id}
          sx={{
            p: "2px",
          }}
          title={
            <>
              <Tooltip
                title={translate("Mark this notification as read")}
                placement="top"
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    readNotification(notification.id);
                    setNotifications(
                      notifications?.filter(
                        (noti) => noti.id != notification.id
                      )
                    );
                  }}
                  sx={{
                    borderRadius: 0.5,
                    m: 0,
                    p: "2px",
                  }}
                >
                  <NotificationReadIcon height={15} width={15} />
                </IconButton>
              </Tooltip>
            </>
          }
          placement="top-end"
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -30],
                },
              },
            ],
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                {
                  marginTop: "0px",
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                {
                  marginBottom: "0px",
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                {
                  marginLeft: "0px",
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                {
                  marginRight: "0px",
                },
            },
          }}
        >
          <ListItem
            sx={{
              pl: 1,
              alignItems: "flex-start",
              "&:hover": {
                bgcolor: theme.palette.action.hover,
              },
              cursor: "pointer",
              borderRadius: 0.5,
            }}
            key={notification?.id}
          >
            <Typography
              // noWrap
              sx={{
                fontWeight: 400,
                fontSize: 11,
              }}
            >
              <b>{formatCount(notification.count)}</b> &nbsp;
              {translate(notification.message)}
            </Typography>
          </ListItem>
        </Tooltip>
      ))}
    </>
  );
};

export default NotificationAlertBox;

const formatCount = (count: number) => {
  if (count > 0 && count < 10) {
    return `0${count}`;
  }
  return count;
};

export const CircularButton = ({ onCLick }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        textAlign={"right"}
        sx={{
          marginLeft: "auto",
          borderRadius: "50%",
          border: "2px solid",
          position: "relative",
          padding: "2px",
          height: 26,
          width: 26,
          display: "flex",
          alignItems: "center",
          borderColor: theme.palette.warning.dark,
          bgcolor: lighten(theme.palette.warning.light, 0.6),
          cursor: "pointer",
        }}
        onClick={onCLick}
      >
        <RightArrowIcon
          height={18}
          width={18}
          customColor={theme.palette.warning.dark}
        />
      </Box>
    </>
  );
};

const SquareButton = ({ onCLick, children }) => {
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      size="small"
      sx={{
        bgcolor: lighten(theme.palette.warning.light, 0.6),
        borderColor: theme.palette.warning.dark,
        color: theme.palette.warning.dark,
      }}
      onClick={onCLick}
      endIcon={
        <RightArrowIcon
          height={18}
          width={18}
          customColor={theme.palette.warning.dark}
        />
      }
    >
      {children}
    </Button>
  );
};

export const DocumentAlertBox = ({ count, status, setStatus }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  if (status == "0") {
    return (
      <>
        <Alert
          severity={"info"}
          variant="outlined"
          icon={false}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
          }}
        >
          <Box
            p={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"row"} spacing={1}>
              <InfoIcon customColor={theme.palette.info.dark} />
              <p
                style={{
                  fontWeight: 800,
                  fontSize: 16,
                }}
              >
                {translate("Extracting Documents", {
                  count: count,
                  s: count > 1 ? "s" : "",
                })}
              </p>
            </Stack>
            <CrossRoundButton
              color={"info"}
              onCLick={() => {
                setStatus("");
              }}
            />
          </Box>
        </Alert>
      </>
    );
  } else if (status == "1") {
    return (
      <>
        <Alert
          severity={"success"}
          variant="outlined"
          icon={false}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
          }}
        >
          <Box
            p={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"row"} spacing={1}>
              <InfoIcon customColor={theme.palette.success.dark} />
              <p
                style={{
                  fontWeight: 800,
                  fontSize: 16,
                }}
              >
                {translate("The document extraction is completed")}
              </p>
            </Stack>
            <CrossRoundButton
              color={"success"}
              onCLick={() => {
                setStatus("");
              }}
            />
          </Box>
        </Alert>
      </>
    );
  }
  return <></>;
};
export const ReviewAlertBox = ({ setAlert }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  return (
    <>
      <Alert
        severity={"error"}
        variant="outlined"
        icon={<InfoIcon customColor={theme.palette.error.dark} />}
        action={
          <CrossRoundButton
            color={"error"}
            onCLick={() => {
              setAlert(false);
            }}
          />
        }
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
        }}
      >
        <AlertTitle>{translate("Review Required")}</AlertTitle>
        {translate("Some fields need to be reviewed. Show info for details.")}
      </Alert>
    </>
  );
};

const CrossRoundButton = ({ onCLick, color }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        textAlign={"center"}
        sx={{
          borderRadius: "50%",
          position: "relative",
          padding: "2px",
          height: 26,
          width: 26,
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          bgcolor: lighten(theme.palette[color].light, 0.8),
          cursor: "pointer",
        }}
        onClick={onCLick}
      >
        <Multiply width={18} height={18} color={color} />
      </Box>
    </>
  );
};
