import React from "react";
import { Box } from "@mui/material";

const AnalyticsIcon = ({
  height = 24,
  width = 24,
  color = "#657482",
  sx = {},
}) => {
  return (
    <Box sx={sx}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.77778 9.89444C9.77778 9.73321 9.80954 9.57356 9.87124 9.42459C9.93294 9.27563 10.0234 9.14028 10.1374 9.02627C10.2514 8.91226 10.3867 8.82183 10.5357 8.76013C10.6847 8.69842 10.8443 8.66667 11.0056 8.66667H12.9944C13.1557 8.66667 13.3153 8.69842 13.4643 8.76013C13.6133 8.82183 13.7486 8.91226 13.8626 9.02627C13.9766 9.14028 14.0671 9.27563 14.1288 9.42459C14.1905 9.57356 14.2222 9.73321 14.2222 9.89444V20.7722C14.2222 21.0979 14.0929 21.4101 13.8626 21.6404C13.6324 21.8706 13.3201 22 12.9944 22H11.0056C10.6799 22 10.3676 21.8706 10.1374 21.6404C9.90713 21.4101 9.77778 21.0979 9.77778 20.7722V9.89444Z"
          stroke="#657482"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5556 3.22778C17.5556 2.90215 17.6849 2.58986 17.9152 2.35961C18.1454 2.12935 18.4577 2 18.7833 2H20.7722C21.0979 2 21.4101 2.12935 21.6404 2.35961C21.8706 2.58986 22 2.90215 22 3.22778V20.7722C22 21.0979 21.8706 21.4101 21.6404 21.6404C21.4101 21.8706 21.0979 22 20.7722 22H18.7833C18.4577 22 18.1454 21.8706 17.9152 21.6404C17.6849 21.4101 17.5556 21.0979 17.5556 20.7722V3.22778Z"
          stroke="#657482"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 19.7778C2 20.3671 2.23413 20.9324 2.65087 21.3491C3.06762 21.7659 3.63285 22 4.22222 22C4.81159 22 5.37682 21.7659 5.79357 21.3491C6.21032 20.9324 6.44444 20.3671 6.44444 19.7778C6.44444 19.1884 6.21032 18.6232 5.79357 18.2064C5.37682 17.7897 4.81159 17.5556 4.22222 17.5556C3.63285 17.5556 3.06762 17.7897 2.65087 18.2064C2.23413 18.6232 2 19.1884 2 19.7778Z"
          stroke="#657482"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default AnalyticsIcon;
