import { useTheme } from "@mui/material";
import { SvgIconProps } from "@mui/material";
import React from "react";

export const CalendarIcon = ({
  color = "primary",
  height = 18,
  width = 18,
}: SvgIconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 14V13.25C5.58579 13.25 5.25 13.5861 5.25 14H6ZM10 14H10.75C10.75 13.5861 10.4142 13.25 10 13.25V14ZM10 18V18.75C10.4142 18.75 10.75 18.4146 10.75 18H10ZM6 18H5.25C5.25 18.4146 5.58579 18.75 6 18.75V18ZM22 20H21.25H22ZM20 22V21.25V22ZM20 4V3.25V4ZM22 6H22.75H22ZM2 6H2.75H2ZM4 4V4.75V4ZM2 20H1.25H2ZM4 22V22.75V22ZM6.75 2C6.75 1.58579 6.41422 1.25 6.00001 1.25C5.5858 1.25 5.25001 1.58579 5.25001 2L6.75 2ZM5.25001 6C5.25001 6.41421 5.58579 6.75 6.00001 6.75C6.41422 6.75 6.75001 6.41421 6.75001 6H5.25001ZM18.75 2C18.75 1.58579 18.4142 1.25 18 1.25C17.5858 1.25 17.25 1.58579 17.25 2H18.75ZM17.25 6C17.25 6.41421 17.5858 6.75 18 6.75C18.4142 6.75 18.75 6.41421 18.75 6H17.25ZM6 14.75H10V13.25H6V14.75ZM9.25 14V18H10.75V14H9.25ZM10 17.25H6V18.75H10V17.25ZM6.75 18V14H5.25V18H6.75ZM2.5 10.75H21.5V9.25H2.5V10.75ZM2.75 20L2.75 6H1.25L1.25 20H2.75ZM4 4.75L20 4.75V3.25L4 3.25V4.75ZM21.25 6L21.25 20H22.75V6H21.25ZM20 21.25L4 21.25V22.75L20 22.75V21.25ZM21.25 20C21.25 20.6904 20.6904 21.25 20 21.25V22.75C21.5188 22.75 22.75 21.5188 22.75 20H21.25ZM20 4.75C20.6904 4.75 21.25 5.30964 21.25 6H22.75C22.75 4.47765 21.5188 3.25 20 3.25V4.75ZM2.75 6C2.75 5.30964 3.30964 4.75 4 4.75V3.25C2.48122 3.25 1.25 4.47765 1.25 6H2.75ZM1.25 20C1.25 21.5188 2.48122 22.75 4 22.75V21.25C3.30965 21.25 2.75 20.6904 2.75 20H1.25ZM5.25001 2L5.25001 6H6.75001L6.75001 2L5.25001 2ZM17.25 2V6H18.75V2H17.25Z"
        fill={theme.palette?.[color]?.light}
      />
    </svg>
  );
};
