import {
  Stack,
  Typography,
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useLocales from "src/hooks/useLocales";
import DeleteIcon from "./../../../assets/icon/delete";
import { IconButton } from "@mui/material";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";

export default function DeleteDocumentModal({
  isShowModal,
  showModalMethod,
  selected,
  deleteDocument,
  totalCount,
  deleteLoader,
}) {
  const { translate } = useLocales();

  return (
    <Dialog
      open={isShowModal}
      onClose={showModalMethod}
      fullWidth
      maxWidth={"sm"}
      scroll="body"
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h5" sx={{}}>
            {selected?.length == 1
              ? `${translate("delete")} ${translate("document")}?`
              : `${translate("delete")} ${selected?.length} ${translate(
                  "documents"
                )}?`}
          </Typography>
          <IconButton sx={{ height: 30, width: 30 }} onClick={showModalMethod}>
            <CloseModalButton />
          </IconButton>
        </Box>
        <Stack spacing={2}>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", pt: 1 }}
            align="center"
          >
            {selected?.length == 1
              ? translate("delete_document_approval")
              : selected?.length == totalCount?.length
              ? translate(`delete_all_documents`)
              : translate("delete_documents_approval")}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.primary", fontWeight: "bold" }}
            align="center"
          >
            {translate("delete_documents_warning")}
          </Typography>
        </Stack>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => showModalMethod()}
              size="medium"
              variant="outlined"
            >
              {translate("cancel_button")}
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={deleteLoader}
              onClick={() => deleteDocument()}
              loadingPosition="start"
              sx={{ ml: 1 }}
              size="medium"
              startIcon={
                <DeleteIcon customColor="white" height={20} width={20} />
              }
            >
              {translate("delete")}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
