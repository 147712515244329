import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { dialogboxStyles } from "../../styles";
import TableItems from "./TableRow";
import useLocales from "src/hooks/useLocales";
import { IconButton } from "@mui/material";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import usePagination from "src/pages/formvalidation/hooks/usePagination";
import MyTablePagination from "src/components/pagination/TablePagination";
import { SelectChangeEvent } from "@mui/material";
import { Headings } from "./Header";

interface ItemsTableTableProps {
  openItems: boolean;
  handleItemsClose: MouseEventHandler;
  label: Array<any>;
  title: string;
}

export default function ItemsTable(props: ItemsTableTableProps) {
  const { openItems, handleItemsClose, label, title } = props;
  const { translate } = useLocales();
  const [labels, setLabels] = useState<any>([]);
  const [rows, headings] = createDataFromLabels();
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } =
    usePagination({ totalItems: rows.length });
  useEffect(() => {
    setLabels(label);
  }, [label]);

  type TRow = {
    [key: string]: {
      key: string;
      value: string;
    };
  };
  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") == -1) {
      return result;
    }
    return title;
  };

  function createDataFromLabels(): [TRow[], string[]] {
    const result = labels?.reduce(
      (accumulator, l) => {
        const lineNumber = l.ind;
        const newItem = {
          line: +lineNumber + 1,
          [l.key]: { value: l.value },
        };

        if (!accumulator.itemsMap.has(lineNumber)) {
          accumulator.itemsMap.set(lineNumber, newItem);
        } else {
          const existingItem = accumulator.itemsMap.get(lineNumber);
          const updatedItem = {
            ...existingItem,
            [l.key]: { value: l.value },
          };
          accumulator.itemsMap.set(lineNumber, updatedItem);
        }

        accumulator.itemsHeadings.add(l.key);
        return accumulator;
      },
      { itemsMap: new Map(), itemsHeadings: new Set() }
    );

    const itemsArray: TRow[] = Array.from(result.itemsMap.values());
    const itemsHeadings: string[] = Array.from(result.itemsHeadings);
    return [itemsArray, itemsHeadings];
  }

  return (
    <Dialog
      open={openItems}
      onClose={handleItemsClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={dialogboxStyles}
      scroll="body"
      maxWidth="lg"
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {getTranslatedFields(title)}
          <IconButton size="small" onClick={handleItemsClose}>
            <CloseModalButton />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TableContainer
          sx={{
            maxHeight: "50vh",
          }}
        >
          <Table size="small" aria-label="table with items">
            <TableHead>
              <Headings headers={headings} />
            </TableHead>
            <TableBody>
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => (
                  <TableItems row={row} header={headings} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <MyTablePagination
            count={rows.length}
            rowsPerPage={rowsPerPage}
            disableSelectRowsPerPage
            labelRowsPerPage={translate("finance_screen.rows_per_page")}
            page={page}
            onPageChange={(event, page) => {
              onChangePage(event, page);
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
