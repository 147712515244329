import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material';

const ClockIcon = ({ height = 24, width = 24, color = 'primary' }) => {
  const theme = useTheme();
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 8V12L15.5 14"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 12C22 17.5229 17.5229 22 12 22C6.4773 22 2 17.5229 2 12C2 6.4771 6.4773 2 12 2C17.5229 2 22 6.4771 22 12Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default ClockIcon;
