import { Box, Divider, Drawer, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { ReactComponent as HistoryIcon } from "src/assets/icon/history.svg";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import { IconButton } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { FileExtension } from "src/components/FileExtension";
import useDynamicTranslate from "src/hooks/useDynamicTranslation";

const MessageDrawer = ({ openDrawer, selectedRow, handleDrawerClose }) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const { dynamicTranslation } = useDynamicTranslate();

  let { fileName, ...row } = selectedRow || {};
  const messageKeys = dynamicTranslation(row?.messageKeys, "messageKey");
  return (
    <>
      <Drawer
        open={openDrawer}
        variant="persistent"
        anchor="right"
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: 400,
              md: 400,
              lg: 400,
              xl: 400,
            },
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
            bgcolor: "background.paper",
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.standard,
              }),
            boxShadow: (theme) => theme.customShadows.z24,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* <HistoryIcon stroke={theme.palette.action.active} /> */}
            <Typography variant="h6">
              {translate("Document Messages")}
            </Typography>
          </Box>
          <IconButton sx={{ width: 10, height: 8 }} onClick={handleDrawerClose}>
            <CloseModalButton />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            overflow: "auto",
            height: "calc(100vh - 64px)",
          }}
        >
          <Box
            sx={{
              padding: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                // justifyContent: "space-between",
              }}
            >
              <Box p={0.25}>
                <FileExtension
                  ext={fileName?.split(".").pop()}
                  height="25px"
                  width={"25px"}
                  style={{
                    color: theme.palette.text.greyLight,
                  }}
                />
              </Box>
              <Typography variant="subtitle2">{fileName}</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  py: 1,
                }}
                variant="body2"
              >
                {translate("Messages")}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {messageKeys?.map((condition, i) => (
                  <>
                    <Box
                      id={`comp${i + 1}`}
                      sx={{
                        p: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        bgcolor: "secondary.light",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                        }}
                        variant="body2"
                      >
                        {translate(condition)}
                      </Typography>
                    </Box>
                    {/* {messageKeys?.length - 1 !== i && <Divider />} */}
                  </>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default MessageDrawer;
