import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { SvgIconProps } from "@mui/material";

const CopyIcon = ({ color = "primary", height = "16px", width = "16px" }) => {
  const theme = useTheme();
  return (
    <Box>
      <svg
        width={height}
        height={width}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.4375 5.4375H19.6875C20.7921 5.4375 21.6875 6.33293 21.6875 7.4375V19.6875C21.6875 20.7921 20.7921 21.6875 19.6875 21.6875H7.4375C6.33293 21.6875 5.4375 20.7921 5.4375 19.6875V7.4375M7.4375 5.4375C6.33293 5.4375 5.4375 6.33293 5.4375 7.4375M7.4375 5.4375H18.5625V4.3125C18.5625 3.20793 17.6671 2.3125 16.5625 2.3125H4.3125C3.20793 2.3125 2.3125 3.20793 2.3125 4.3125V16.5625C2.3125 17.6671 3.20793 18.5625 4.3125 18.5625H5.4375V7.4375"
          stroke={theme.palette?.[color]?.light ?? color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default CopyIcon;
