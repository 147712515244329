import { TableCell, TableRow } from "@mui/material";
import useLocales from "src/hooks/useLocales";

export function Headings({ headers }: { headers: Array<any> }) {
  const { translate } = useLocales();
  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") == -1) {
      return result;
    }
    return title;
  };
  return (
    <TableRow>
      <TableCell align="left" sx={{ fontWeight: "bold" }}>
        {translate("Line")}
      </TableCell>
      {headers
        ?.sort((a: any, b: any) => {
          if (
            getTranslatedFields(a).toLowerCase() >
            getTranslatedFields(b).toLowerCase()
          ) {
            return 1;
          }
          if (
            getTranslatedFields(a).toLowerCase() <
            getTranslatedFields(b).toLowerCase()
          ) {
            return -1;
          }
          return 0;
        })
        .map((header) => (
          <>
            <TableCell align="left" sx={{ fontWeight: "bold" }}>
              {getTranslatedFields(header)}
            </TableCell>
          </>
        ))}
    </TableRow>
  );
}
