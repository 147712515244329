import { MenuItem } from "@mui/material";

const MyMenuItem = ({ option, displayKey, itemDisplayOpts, t, ...props }) => {
  return (
    <MenuItem {...props} key={props.id}>
      {itemDisplayOpts?.showImage && itemDisplayOpts.imgKey && (
        <span key={option.id} className="c-avatar">
          <img
            src={
              option[itemDisplayOpts.imgKey] &&
              option[itemDisplayOpts.imgKey].path
                ? option[itemDisplayOpts.imgKey].path
                : "avatars/8.jpg"
            }
            className="c-avatar-img"
          />
        </span>
      )}
      <span style={{ marginLeft: 10, fontSize: "12px" }}>
        {itemDisplayOpts?.showColor && (
          <div
            style={{
              height: "22px",
              width: "22px",
              background:
                option[itemDisplayOpts.colorKey ?? "strokeColor"] ??
                "whitesmoke",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "0.2rem",
            }}
          ></div>
        )}
        {t?.(option[displayKey]) ?? option[displayKey]}
      </span>
    </MenuItem>
  );
};

export default MyMenuItem;
