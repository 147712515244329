import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
} from "@mui/material";
import { AutoComplete } from "src/components/FormFields";
import { Box } from "@mui/material";
import { InputLabel } from "@mui/material";
import { BootstrapInput } from "./TextField";
import useLocales from "src/hooks/useLocales";
import { forwardRef, useState } from "react";
import { MenuItem } from "@mui/material";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import { styled, useTheme } from "@mui/material/styles";
import MyErrorHelperText from "../denseForm/ErrorHelperText";

type MyAutoCompleteProps = {
  label?: string;
  options: any[];
  name: string;
  displayKey: string;
  onChange: (e: any, value: any) => void;
  val: any;
  error: string | undefined;
  placeholder?: string;
};

export function ReviewAutoComplete({
  label,
  options,
  name,
  displayKey,
  onChange,
  val,
  placeholder,
  error,
}: MyAutoCompleteProps) {
  return (
    <AutoComplete
      displayKey={displayKey}
      name={name}
      val={val}
      options={options}
      onChange={onChange}
      error={!!error}
      errorText={error}
      label={label}
      placeholder={placeholder}
      style={{ fontSize: "12px" }}
      sx={{
        width: { xs: 160, sm: 160, md: 160, lg: 200, xl: 240 },
        height: 35,
        align: "center",
      }}
    />
  );
}
type NewAutoCompleteFieldProps = {
  label?: string;
  options: any[];
  onChange: (e: any, value: any) => void;
  error?: string | undefined;
  placeholder?: string;
  value: any;
  disabled?: boolean;
  renderInput?: any;
};
export const NewAutoCompleteField = forwardRef(
  (
    {
      label,
      options,
      onChange,
      error,
      placeholder,
      value,
      disabled,
      renderInput,
      ...props
    }: NewAutoCompleteFieldProps &
      Omit<
        AutocompleteProps<any, boolean, boolean, boolean, any>,
        "renderInput"
      >,
    ref
  ) => {
    const { translate } = useLocales();
    const theme = useTheme();
    return (
      <Box>
        <MuiAutocomplete
          size="small"
          value={value}
          disabled={disabled}
          onChange={onChange}
          selectOnFocus
          clearOnBlur
          options={options}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return translate(option);
            }
            return translate(option.label);
          }}
          isOptionEqualToValue={(option, value) => {
            return option.enum == value;
          }}
          renderOption={(props: any, option: any, state: object) => (
            <MenuItem {...props} key={props.id}>
              <span style={{ marginLeft: 10, fontSize: "12px" }}>
                {translate(option?.label ?? "")}
              </span>
            </MenuItem>
          )}
          popupIcon={
            <ChevronDown
              height={24}
              width={22}
              sx={{
                p: "3px",
                mr: `2px`,
              }}
            />
          }
          renderInput={({ ...params }) => {
            const { InputProps, inputProps } = params;
            const { InputLabelProps, ...restParams } = params;
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "flex-start",
                  p: 0,
                  gap: "4px",
                }}
              >
                {label && (
                  <InputLabel
                    htmlFor="demo-customized-textbox"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      color: "action.active",
                    }}
                  >
                    {label}
                  </InputLabel>
                )}
                <BootstrapInput
                  endAdornment={params.InputProps?.endAdornment}
                  ref={InputProps?.ref}
                  placeholder={placeholder}
                  sx={{
                    "& .MuiInputBase-input": {
                      ...(!!error && {
                        backgroundColor: theme.palette.error.light,
                        borderColor: theme.palette.error.dark,
                        color: theme.palette.error.dark,
                        "&::placeholder": {
                          opacity: 1,
                          color: theme.palette.error.dark,
                        },
                        "&:focus": {
                          borderColor: theme.palette.error.dark,
                          boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
                        },
                      }),
                    },
                  }}
                  {...restParams}
                />
                <MyErrorHelperText error={!!error} helperText={error} />
              </Box>
            );
          }}
          {...props}
        />
      </Box>
    );
  }
);
