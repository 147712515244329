//mixpanel
import { Mixpanel } from "./Mixpanel.js";

// redux
import { store } from "../../src/redux/store";
import { ENABLE_ORGANIZATION_CONFIGURATION } from "src/constants/settings.js";

// const { email } = store.getState()?.credential?.userTokenDecode;
// const COMPANY_ID = localStorage.getItem("company_id");

const MixpanelFunction = ({ ...props }) => {
  const { sub, email } = store.getState()?.credential?.userTokenDecode;

  const {
    eventName,
    eventSource,
    user,
    userEmail,
    userProfile,
    status,
    impersonateMode,
    plan,
    signInMethod,
    supplierName,
    supplierEmail,
    supplierPortalURL,
    mandatoryFields,
    optionalFields,
    documentType,
    dateFormat,
    currencyFormat_decimalSeparator,
    currencyFormat_thousandSeparator,
    vendorConfiguration,
    replaceDuplicate,
    url,
    language,
    darkMode,
    workflowEmail,
    workflowName,
    enableReview,
    parameters,
    operations,
    inputValues,
  } = props;

  const ORGANIZATION_ID = localStorage.getItem("company_id");

  if (eventSource == "Authentication") {
    if (user == "Login") {
      const loginProps = {
        "First Name": userProfile?.firstName,
        "Last Name": userProfile?.lastName,
        Email: userProfile?.email,
        Role: userProfile?.roleInOrganization,
        "Declared docs/m": userProfile?.organizationDocumentsPerMonth,
        "Current Plan": userProfile?.organizationPlan,
        "Company Name": userProfile?.organizationName,
        "Company Country": userProfile?.organizationCountry,
        Sector: userProfile?.organizationSector,
        "Sales Motion": userProfile.isPartner
          ? "partner"
          : userProfile.isOutbound
          ? "outbound"
          : "self-serve",
        // userProfile?.isOutbound == true
        //   ? 'outbound'
        //   : userProfile.isPartner == true
        //   ? 'partner'
        //   : 'self-serve',
        "User Role":
          userProfile?.isOrganizationAdmin === true ? "admin" : "user",
        company_id: ORGANIZATION_ID,
        Language: "Spanish",
        $impersonate_mode: impersonateMode === true ? "Yes" : "No",
      };
      Mixpanel.register(loginProps);
      Mixpanel.identify(userEmail);
      var auth_props = {
        $id: sub,
        $status: status,
        $email: email,
        company_id: ORGANIZATION_ID,
        $signIn_method: signInMethod,
      };
      Mixpanel.track(eventName, auth_props);
    } else {
      const signUpMethod = localStorage.getItem("signUpMethod");
      const invitedToTeam = localStorage.getItem("invitedToTeam");
      const userProps = {
        "First Name": userProfile?.firstName,
        "Last Name": userProfile?.lastName,
        Email: userProfile?.email,
        Role: userProfile?.roleInOrganization,
        "Declared docs/m": userProfile?.documentsPerMonth,
        "Current Plan": userProfile?.subscribedPlan,
        "Company Name": userProfile?.organizationName,
        "Company Country": userProfile?.organizationCountry,
        Sector: userProfile?.sectorInOrganization,
        "Sales Motion": userProfile.isPartner
          ? "partner"
          : userProfile.isOutbound
          ? "outbound"
          : "self-serve",
        "User Role": userProfile?.invitedByUser === "" ? "admin" : "user",
        company_id: ORGANIZATION_ID,
        Language: "Spanish",
      };

      Mixpanel.register(userProps);

      const companyProps = {
        "Current Plan": userProfile?.subscribedPlan,
        "Company Name": userProfile?.organizationName,
        "Company Country": userProfile?.organizationCountry,
        "Declared docs/m": userProfile?.documentsPerMonth,
        Sector: userProfile?.sectorInOrganization,
        "Sales Motion": userProfile.isPartner
          ? "partner"
          : userProfile.isOutbound
          ? "outbound"
          : "self-serve",
      };

      let auth_props = {
        // $email: userEmail,
        $status: status,
        // company_id: ORGANIZATION_ID,
        $signUp_Method: signUpMethod,
        $invited_to_a_team: invitedToTeam !== null ? invitedToTeam : "No",
      };
      Mixpanel.identify(userEmail, true, () => {
        Mixpanel.track(eventName, auth_props);
        Mixpanel.people.set(userProps);
        setTimeout(() => {
          Mixpanel.group.set_property(ORGANIZATION_ID, companyProps);
        }, 10000);
      });
    }
  } else if (eventSource === "Profile Update") {
    const auth_props = {
      $id: sub,
      $status: status,
      $email: email,
      company_id: ORGANIZATION_ID,
    };

    const userProps = {
      "First Name": userProfile.firstName,
      "Last Name": userProfile.lastName,
      Role: userProfile.roleInOrganization,
      "Declared docs/m": userProfile.documentsPerMonth,
      // "Current Plan": userProfile.subscribedPlan,
      "Company Name": userProfile.organizationName,
      "Company Country": userProfile.organizationCountry,
      Sector: userProfile.sectorInOrganization,
    };

    Mixpanel.identify(userEmail, false);
    Mixpanel.register(userProps);
    Mixpanel.track(eventSource, auth_props);
    Mixpanel.people.set(userProps);

    const companyProps = {
      "Company Name": userProfile.organizationName,
      "Company Country": userProfile.organizationCountry,
      "Declared docs/m": userProfile.documentsPerMonth,
      Sector: userProfile.sectorInOrganization,
      company_id: ORGANIZATION_ID,
    };
    Mixpanel.group.set_property(ORGANIZATION_ID, companyProps);
  } else if (eventName === "Plan Updated") {
    Mixpanel.identify(userEmail, false);
    // const auth_props = {
    //   "Plan Updated": planType,
    //   "Name plan": plan,
    //   $updated_by_super_admin: true,
    // };

    const props = {
      "Current Plan": plan,
      // "Number of Docs Allowed": documentCount,
      // Addons: addons,
      company_id: ORGANIZATION_ID,
    };

    // Mixpanel.track(eventName, auth_props);
    Mixpanel.people.set(props);
    Mixpanel.group.set_property(ORGANIZATION_ID, props);
  } else if (eventName === "Clicked Dost Academy") {
    Mixpanel.identify(email, false);
    const auth_props = {
      $id: sub,
      $email: email,
      company_id: ORGANIZATION_ID,
    };
    Mixpanel.track(eventName, auth_props);
  } else if (eventSource === "Supplier Portal") {
    Mixpanel.identify(email, false);
    const auth_props = {
      $id: sub,
      $email: email,
      company_id: ORGANIZATION_ID,
      $supplier_name: supplierName,
      $supplier_email: supplierEmail,
      $supplier_portal_URL: supplierPortalURL,
    };
    Mixpanel.track(eventName, auth_props);
  } else if (eventName === "Password Changed") {
    Mixpanel.identify(email, false);
    const auth_props = {
      $id: sub,
      $email: email,
      company_id: ORGANIZATION_ID,
    };
    Mixpanel.track(eventName, auth_props);
  } else if (eventName === "New user invited") {
    Mixpanel.identify(email, false);
    const auth_props = {
      $id: sub,
      $email: email,
      company_id: ORGANIZATION_ID,
    };
    Mixpanel.track(eventName, auth_props);
  } else if (eventName === "Configuration Saved") {
    Mixpanel.identify(email, false);
    const auth_props = {
      $mandatory_fields: mandatoryFields,
      $optional_fields: optionalFields,
      $document_type: documentType,
      $date_format: dateFormat,
      $currency_format_decimal_separator: currencyFormat_decimalSeparator,
      $currency_format_thousand_separator: currencyFormat_thousandSeparator,
      $vendor_configuration: vendorConfiguration,
      $replace_duplicate: replaceDuplicate,
      company_id: ORGANIZATION_ID,
    };

    const new_auth_props = {
      $mandatory_fields: mandatoryFields,
      $optional_fields: optionalFields,
      $date_format: dateFormat,
      $currency_format_decimal_separator: currencyFormat_decimalSeparator,
      $currency_format_thousand_separator: currencyFormat_thousandSeparator,
      $vendor_configuration: vendorConfiguration,
      $replace_duplicate: replaceDuplicate,
      company_id: ORGANIZATION_ID,
    };
    {
      ENABLE_ORGANIZATION_CONFIGURATION
        ? Mixpanel.track(eventName, new_auth_props)
        : Mixpanel.track(eventName, auth_props);
    }
  } else if (eventName === "Workflow Added") {
    Mixpanel.identify(email, false);
    const auth_props = {
      $workflow_email: workflowEmail,
      $workflow_name: workflowName,
      $review_enable: enableReview,
      $workflow_parameters: parameters,
      $workflow_operations: operations,
      $workflow_inputValues: inputValues,
    };
    Mixpanel.track(eventName, auth_props);
  } else if (eventName === "Page Viewed") {
    // props = {
    //   $id: sub,
    //   $page_viewed: url,
    //   company_id: ORGANIZATION_ID,
    // };
    // Mixpanel.track(eventName, props);
  } else if (eventName === "Language") {
    Mixpanel.register({ Language: language });
  } else if (eventName === "Dark Mode") {
    Mixpanel.register({ "Dark Mode": darkMode });
  }
  return;
};

function DocumentUploaded(props) {
  const { email } = store.getState()?.credential?.userTokenDecode;
  const company_id = localStorage.getItem("company_id");
  const { eventName, id, status, documentTypes, documentNames } = props;

  Mixpanel.identify(email);
  if (status) {
    Mixpanel.track(eventName, {
      $status: "successfully uploaded",
      $user_email: email,
      $documentId: id,
      company_id: company_id,
    });
    const typesOfDoc = "Types of documents";
    const namesOfDoc = "Names of documents";

    documentNames.map((item) => {
      Mixpanel.people.union(namesOfDoc, item);
      Mixpanel.group.union(company_id, namesOfDoc, item);
    });

    documentTypes.map((item) => {
      Mixpanel.people.union(typesOfDoc, item);
      Mixpanel.group.union(company_id, typesOfDoc, item);
    });
  } else {
    Mixpanel.track(eventName, {
      $status: "unsuccessful uploaded",
      $user_email: email,
    });
  }
}

export { MixpanelFunction, DocumentUploaded };
