import { useEffect, useRef } from "react";

const useDimentions = ({ imgHeight, imgWidth }) => {
  const heightRef = useRef(0);
  const widthRef = useRef(0);
  let rect = document.getElementById(`review-page`)?.getBoundingClientRect();
  let containerWidth = (rect?.width ?? 0) * 0.6;
  let objectWidth = imgWidth;
  let objectHeight = imgHeight;
  let widthScaleFactor = containerWidth / objectWidth;
  const width_ = objectWidth * widthScaleFactor;
  const height_ = objectHeight * widthScaleFactor;
  heightRef.current = height_;
  widthRef.current = width_;
  return {
    width: widthRef.current,
    height: heightRef.current,
  };
};

export default useDimentions;

export const getDimensions = (
  imgHeight: number,
  imgWidth: number,
  fullWidth: boolean | undefined = false
) => {
  let height = 0;
  let width = 0;
  let rect = document.getElementById(`review-page`)?.getBoundingClientRect();
  let containerWidth = (rect?.width ?? 0) * (fullWidth ? 1 : 0.6);
  let objectWidth = imgWidth;
  let objectHeight = imgHeight;
  let widthScaleFactor = containerWidth / objectWidth;
  const width_ = objectWidth * widthScaleFactor;
  const height_ = objectHeight * widthScaleFactor;
  height = height_;
  width = width_;
  return {
    width,
    height,
  };
};
