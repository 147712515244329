import { Navigate, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Divider, Grid, Grow, Tooltip, Typography } from "@mui/material";
// hooks
import useLocales from "../../../hooks/useLocales";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from "../../../routes/paths";
// @mui
import useAuth from "../../../hooks/useAuth";
import Label from "src/components/Label";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import MyAvatar from "../../../components/MyAvatar";
import { store } from "src/redux/store";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useEffect, useState } from "react";
import { GetAsync } from "src/common/httpRequests";
import {
  API_GATEWAY,
  ENABLE_USER_ACCOUNT_STATUS,
} from "src/constants/settings";
import Skeleton from "@mui/material/Skeleton";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import { MenuItem } from "@mui/material";
import MenuPopover from "src/components/MenuPopover";
import { ChevronRight } from "@mui/icons-material";
import BookIcon from "src/assets/icon/book";
import { MixpanelFunction } from "src/mixpanel/mixpanelFunction";
import UserIcon from "src/assets/icon/user";
import UsersIcon from "src/assets/icon/users";
import LogoutIcon from "src/assets/icon/logout";
import { shutDownIntercomm } from "src/intercom";
import { useSnackbar } from "notistack";
import SettingsIcon from "src/assets/icon/settings";
import checkAdmin from "src/utils/checkAdmin";
import ChevronUpIcon from "src/assets/icon/chevron/up";
import usePermissions from "src/hooks/usePermissions";
import AnalyticsIcon from "src/assets/icon/Analytics";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.background.paper,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const userInfo = store.getState()?.credential?.userCred;
  const {
    org: { enableSelfTraining, plan },
  } = store.getState()?.organization;
  const [accountStatus, setAccountStatus] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const [firstNameTooltipEnabled, setFirstNameTooltipEnabled] = useState(false);
  const [emailTooltipEnabled, setEmailTooltipEnabled] = useState(false);

  const handleShowTooltip = (
    { currentTarget }: React.MouseEvent<Element>,
    tooltipName
  ) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      if (tooltipName === "firstName") {
        setFirstNameTooltipEnabled(true);
      } else if (tooltipName === "email") {
        setEmailTooltipEnabled(true);
      }
    }
  };

  const hideTooltip = (tooltipName) => {
    if (tooltipName === "firstName") setFirstNameTooltipEnabled(false);
    else if (tooltipName === "email") setEmailTooltipEnabled(false);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const getAccountStatus = () => {
    GetAsync(`${API_GATEWAY}/api/userservice/account/status`)
      .then((res) => {
        setAccountStatus(res.data);
      })
      .catch((err) => {});
  };

  const t = (text: string): string => {
    let result = translate(`navbar_account.${text}`);
    if (result.indexOf("navbar_account") == -1) {
      return result;
    }
    return text;
  };

  const theme = useTheme();

  const useStyles = makeStyles((theme_) => ({
    name: {
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
    },
    badgeText: {
      fontWeight: "bold",
      marginBottom: theme.spacing(0),
      fontSize: theme.spacing(1.2),
    },
  }));

  const COMPANY_ID: string | null = localStorage.getItem("company_id");

  function redirectToAcademy(): void {
    window.open("https://academy.mydost.ai/knowledge");
    MixpanelFunction({
      eventName: "Clicked Dost Academy",
      organizationId: COMPANY_ID,
    });
  }

  const handleLogout = async () => {
    try {
      await logout();
      shutDownIntercomm();
      localStorage.removeItem("customLogo");
      navigate(PATH_AUTH.login, { replace: true });
      localStorage.removeItem("company_id");
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  useEffect(() => {
    if (ENABLE_USER_ACCOUNT_STATUS) {
      getAccountStatus();
    }
  }, [userInfo]);

  const { permissionHandle } = usePermissions();
  const readDeveloperPage = permissionHandle([
    "DeveloperSettings_Read",
    "DeveloperSettings_Write",
  ]);
  const readUser = permissionHandle([
    "UserManagement_Read",
    "UserManagement_Write",
    "RoleManagement_Read",
    "RoleManagement_Write",
  ]);

  return (
    <>
      <RootStyle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setAnchorEl(e.currentTarget);
          }}
        >
          <Box display="flex">
            <MyAvatar user={userInfo} />
            <Box
              sx={{
                ml: 2,
                transition: (theme) =>
                  theme.transitions.create("width", {
                    duration: theme.transitions.duration.shorter,
                  }),
                ...(isCollapse && { ml: 0, width: 0 }),
              }}
            >
              <Tooltip
                title={userInfo.firstName ? userInfo.firstName : ""}
                onMouseEnter={(event) => handleShowTooltip(event, "firstName")}
                onMouseLeave={() => hideTooltip("firstName")}
                disableHoverListener={!firstNameTooltipEnabled}
                placement="top"
                arrow
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    maxWidth: 140,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {userInfo?.firstName}
                </Typography>
              </Tooltip>
              <Tooltip
                title={userInfo.email}
                onMouseEnter={(event) => handleShowTooltip(event, "email")}
                onMouseLeave={() => hideTooltip("email")}
                disableHoverListener={!emailTooltipEnabled}
                placement="top"
                arrow
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    color: "text.secondary",
                    maxWidth: 140,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {userInfo?.email}
                </Typography>
              </Tooltip>
              {accountStatus ? (
                <Tooltip
                  title={
                    !accountStatus?.isEmailVerified
                      ? translate("navbar_account.Unverified")
                      : ""
                  }
                >
                  <Typography
                    variant="body2"
                    noWrap
                    sx={{
                      color: "text.secondary",
                      fontSize: theme.spacing(1.2),
                    }}
                  >
                    {!accountStatus?.isEmailVerified && (
                      <Label
                        variant={
                          theme.palette.mode === "light" ? "ghost" : "filled"
                        }
                        color="error"
                        sx={{
                          textTransform: "capitalize",
                          height: "0.9rem",
                          fontWeight: 500,
                        }}
                      >
                        {translate("navbar_account.Unverified")}
                      </Label>
                    )}
                  </Typography>
                </Tooltip>
              ) : (
                <>
                  <Skeleton variant="text" width={80} height={15} />
                </>
              )}
            </Box>
          </Box>
          {!isCollapse && (
            <Box
              id="composition-button"
              sx={{
                position: "relative",
                my: "auto",
                transition: (theme) =>
                  theme.transitions.create("width", {
                    duration: theme.transitions.duration.shorter,
                  }),
              }}
            >
              {anchorEl === null ? (
                <ChevronDown height={20} width={20} />
              ) : (
                <ChevronUpIcon height={20} width={20} />
              )}
            </Box>
          )}
        </Box>
        <MenuPopover
          disabledArrow
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: 0, horizontal: 0 }}
          arrow="right-top"
          sx={{
            mt: -1,
            width: 250,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              "& svg": { mr: 2, width: 20, height: 20 },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              p: 1,
            }}
          >
            <Label
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "capitalize",
                height: "0.9rem",
                fontWeight: 500,
                fontSize: theme.spacing(1.2),
                my: "auto",
              }}
            >
              {translate(plan)}
            </Label>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigate("/dashboard/upgrade");
              }}
            >
              <Typography
                variant="subtitle2"
                noWrap
                sx={{ color: "text.secondary", my: "auto" }}
              >
                {translate("upgrade")}
              </Typography>
              <Box>
                <ChevronRight />
              </Box>
            </Box>
          </Box>
          <Divider />
          <MenuItem onClick={() => redirectToAcademy()}>
            <BookIcon />
            <Typography variant="subtitle2" noWrap>
              {translate("Dost Academy")}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/user/account");
              onClose();
            }}
          >
            <UserIcon />
            <Typography variant="subtitle2" noWrap>
              {translate("My account")}
            </Typography>
          </MenuItem>
          {readUser && (
            <MenuItem
              onClick={() => {
                navigate("/dashboard/usersV2");
                onClose();
              }}
            >
              <UsersIcon />
              <Typography variant="subtitle2" noWrap>
                {translate("Users management")}
              </Typography>
            </MenuItem>
          )}
          {enableSelfTraining && (
            <MenuItem
              onClick={() => {
                navigate("/dashboard/selftrainingreports");
                onClose();
              }}
            >
              <AnalyticsIcon />
              <Typography variant="subtitle2" noWrap>
                {translate("self_training_reports")}
              </Typography>
            </MenuItem>
          )}
          {readDeveloperPage && (
            <MenuItem
              onClick={() => {
                navigate("/dashboard/developers-settings");
                onClose();
              }}
            >
              <SettingsIcon />
              <Typography variant="subtitle2" noWrap>
                {translate("Developers settings")}
              </Typography>
            </MenuItem>
          )}
          <MenuItem onClick={() => handleLogout()}>
            <LogoutIcon />
            <Typography variant="subtitle2" noWrap>
              {translate("Logout")}
            </Typography>
          </MenuItem>
        </MenuPopover>
        {/* {isCollapse
          ? null
          : accountStatus?.isSubscriptionOnTrial && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={classes.badgeText} variant="caption">
                    <CalendarMonthIcon
                      fontSize={"small"}
                      sx={{ height: "0.8rem", width: "0.8rem" }}
                    />
                    {translate("days_left")}
                  </Typography>
                  <Typography className={classes.badgeText} variant="caption">
                    {accountStatus?.daysLeftInSubscriptionTrialExpiry ?? 0}{" "}
                    {translate("of")} 14
                  </Typography>
                </Box>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    (accountStatus?.daysLeftInSubscriptionTrialExpiry / 14) *
                    100
                  }
                />
              </Grid>
            )} */}
      </RootStyle>
    </>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "white",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#2E435E" : "#308fe8",
  },
}));
