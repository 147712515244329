import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PrismaZoom from "react-prismazoom";
import { Grid } from "@mui/material";
import { PageDataViewProps } from "../../types/types";
import ImageMapper, {
  AreaEvent,
} from "src/pages/formvalidation/Component/ImageMapper/ImageMapper";
import ReviewPageSkeleton from "../reviewPageSkeleton";
import Loader from "src/components/loading/loader";
import useLocales from "src/hooks/useLocales";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PageDataView = ({
  toolbarprops,
  imageLoading,
  editOptionsProps,
  alreadyLabelledAreas,
  height,
  width,
  MAP,
  ...props
}: PageDataViewProps) => {
  const { gatherEvents, addAreaToCombine } = editOptionsProps;
  const { stayMultiHighlighted, editMode } = toolbarprops;
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const [pdf, setPdf] = useState<any>();
  const [imageUri, setImageUri] = useState("");
  useEffect(() => {
    setType(props.invoiceType);
    if (props.base64String) {
      if (props.invoiceType?.toLowerCase() == "application/pdf") {
        const byteCharacters = atob(props.base64String ?? "");
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        let pdf_ = new Blob([byteArray], { type: "application/pdf" });
        setPdf(pdf_);
      } else {
        const imageUri_ = `data:image/${props.data?.type};base64,${props.base64String}`;
        setImageUri(imageUri_);
      }
    }
  }, [props.invoiceType, props.data, props.base64String]);

  const onDocumentLoadSuccess = () => {
    const importPDFCanvas: HTMLCanvasElement | any = document.querySelector(
      ".import-pdf-page canvas"
    );
    const pdfAsImageSrc = importPDFCanvas?.toDataURL();
    setImage(pdfAsImageSrc);
  };

  const clicked = (area: any, ind: number, evt) => {
    props.onClick(area, ind, evt);
  };

  const onMouseEnter = (area: any) => {
    props.onMouseEnter(area);
  };

  const onMouseLeave = (area: any) => {
    props.onMouseLeave(area);
  };

  props.values.map = MAP;
  const { translate } = useLocales();
  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        sx={{
          mt: 4,
          ml: 2,
          cursor: "pointer",
          height: "78vh",
          display: "flex",
          justifyContent: "center",
          top: { md: 30 },
          width: { md: "200px" },
        }}
      >
        {type?.toLowerCase() == "application/pdf" ? (
          <>
            {!image ? (
              imageLoading ? (
                <Loader />
              ) : (
                <Document
                  file={pdf}
                  error={translate("Error loading PDF")}
                  noData={translate("No PDF file specified")}
                  loading={<Loader />}
                >
                  <Page
                    className="import-pdf-page"
                    onRenderSuccess={onDocumentLoadSuccess}
                    pageNumber={1}
                    loading={<Loader />}
                  />
                </Document>
              )
            ) : image && props.values?.map ? (
              <>
                <PrismaZoom
                  ref={props.prismaZoomRef}
                  allowParentPanning
                  allowTouchEvents
                  onZoomChange={(e) => {
                    props.setZoom(e);
                  }}
                >
                  <ImageMapper
                    src={image}
                    map={MAP}
                    height={height}
                    width={width}
                    // rerenderProps={["map"]}
                    onClick={(area: any, ind: number, evt: AreaEvent) => {
                      if (editMode) {
                        if (area?.key != "") {
                          alreadyLabelledAreas[ind] = area;
                        }
                        addAreaToCombine(area);
                        gatherEvents(evt);
                      } else {
                        clicked(area, ind, evt);
                      }
                    }}
                    onMouseEnter={(area) => onMouseEnter(area)}
                    onMouseLeave={(area) => onMouseLeave(area)}
                    stayHighlighted={false}
                    stayMultiHighlighted={stayMultiHighlighted}
                    // toggleHighlighted={true}
                    strokeColor={stayMultiHighlighted ? "grey" : undefined}
                  />
                </PrismaZoom>
              </>
            ) : (
              <ReviewPageSkeleton />
            )}
          </>
        ) : imageUri && props.values?.map ? (
          <PrismaZoom ref={props.prismaZoomRef}>
            <ImageMapper
              src={imageUri}
              map={props.values.map}
              height={height}
              width={width}
              onClick={(area: any, ind: number, evt: AreaEvent) => {
                if (editMode) {
                  if (area?.key != "") {
                    alreadyLabelledAreas[ind] = area;
                  }
                  addAreaToCombine(area);
                  gatherEvents(evt);
                } else {
                  clicked(area, ind, evt);
                }
              }}
              onMouseEnter={(area) => onMouseEnter(area)}
              onMouseLeave={(area) => onMouseLeave(area)}
              stayHighlighted={false}
              stayMultiHighlighted={stayMultiHighlighted}
              // toggleHighlighted={true}
              strokeColor={stayMultiHighlighted ? "grey" : undefined}
            />
          </PrismaZoom>
        ) : (
          <Loader />
        )}
      </Grid>
    </Grid>
  );
};

export default PageDataView;
