import { alpha, useTheme } from "@mui/material/styles";
import { TableRow, TableCell, Typography, Stack } from "@mui/material";
import { Skeleton, Button, IconButton, Tooltip, Box } from "@mui/material";
import { fDateTime } from "src/utils/formatTime";
import useLocales from "../../../hooks/useLocales";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { FileExtension } from "src/components/FileExtension";
import { DocumentProcessingIcon } from "src/assets/icon/documentProccessed";
import { WorkflowContext } from "../Workflow";
import Label from "src/components/Label";
import usePermissions from "src/hooks/usePermissions";
import { WorkflowApprovalDocuments } from "../type";

type Props = {
  row: WorkflowApprovalDocuments;
  selected?: boolean;
  loader: Boolean;
  handleClickOpen: Function;
  setDocument: SetStateAction<any>;
};

export default function FinanceTableRow({
  row,
  handleClickOpen,
  loader,
  setDocument,
}: Props) {
  const theme = useTheme();
  const { setFileId, setApprovalNotRequired, handleOpenReviewModal } =
    useContext(WorkflowContext);
  const { translate } = useLocales();
  const {
    fileName,
    status,
    createdOn,
    updatedOn,
    documentId,
    approvalNotRequired,
    documentStatus,
  } = row;
  const { id, isReviewDocument, workflowName, workflowId, documentUrl } = row;
  const fileExtension = fileName?.split(".").pop()?.toLowerCase();

  const GetBlobUrlFrom = async (documentUrl: string) => {
    window.open(documentUrl);
  };
  const statusDisable = status?.toString() != "0";
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShowTooltip = ({ currentTarget }: React.MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const hideTooltip = () => setTooltipEnabled(false);

  const { permissionHandle } = usePermissions();

  const writeWorkflowApproval = permissionHandle(["WorkflowApproval_Write"]);
  return (
    <TableRow hover>
      <TableCell
        sx={{
          display: !loader ? "flex" : "",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => GetBlobUrlFrom(documentUrl)}
      >
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <>
            <Box p={0.25}>
              <FileExtension
                ext={fileExtension}
                height="25px"
                width={"25px"}
                style={{
                  color: theme.palette.text.greyLight,
                }}
              />
            </Box>
            <Stack>
              <Tooltip
                title={fileName}
                onMouseEnter={handleShowTooltip}
                onMouseLeave={hideTooltip}
                disableHoverListener={!tooltipEnabled}
                placement="top"
                arrow
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    textOverflow: "ellipsis",
                    width: 150,
                    cursor: "pointer",
                  }}
                >
                  {fileName}
                </Typography>
              </Tooltip>
              <div>
                <Label
                  variant={
                    theme.palette.mode === "light" ? "outlined" : "outlined"
                  }
                  color={
                    (documentStatus == 0 && "warning") ||
                    (documentStatus == 1 && "success") ||
                    (documentStatus == 2 && "error") ||
                    (documentStatus == 3 && "error") ||
                    (documentStatus == 4 && "warning") ||
                    (documentStatus == 5 && "success") ||
                    (documentStatus == 6 && "primary") ||
                    "error"
                  }
                  sx={{ textTransform: "capitalize" }}
                >
                  {documentStatus == 0
                    ? `${translate("status.in_process")}`
                    : documentStatus == 1
                    ? `${translate("status.processed")}`
                    : documentStatus == 2
                    ? `${translate("status.failed")}`
                    : documentStatus == 3
                    ? `${translate("status.error")}`
                    : documentStatus == 4
                    ? `${translate("status.need_review")}`
                    : documentStatus == 5
                    ? `${translate("status.reviewed")}`
                    : documentStatus == 6
                    ? `${translate("status.duplicate")}`
                    : "-"}
                </Label>
              </div>
            </Stack>
          </>
        )}
      </TableCell>
      <TableCell align="left">
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <Box>
            <Tooltip title={workflowName} placement="top" arrow>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  textOverflow: "ellipsis",
                  color: "text.greyLight",
                  width: 170,
                }}
              >
                {workflowName}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </TableCell>
      <TableCell align="left">
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <Box>
            <Typography
              variant="body2"
              noWrap
              sx={{ textOverflow: "ellipsis", color: "text.greyLight" }}
            >
              {updatedOn ? fDateTime(updatedOn) : fDateTime(createdOn)}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell align="left">
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <>
            {approvalNotRequired ? (
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color="info"
              >
                <Typography
                  variant="body2"
                  noWrap
                  sx={{ textOverflow: "ellipsis" }}
                >
                  {translate(`approval_not_required`)}
                </Typography>
              </Label>
            ) : (
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={
                  (status.toString() === "0" && "warning") ||
                  (status.toString() === "1" && "success") ||
                  (status.toString() === "2" && "error") ||
                  "error"
                }
              >
                <Typography
                  variant="body2"
                  noWrap
                  sx={{ textOverflow: "ellipsis" }}
                >
                  {status.toString() === "0"
                    ? `${translate("Pending approval")}`
                    : status.toString() === "1"
                    ? `${translate("approval_status.approved")}`
                    : status.toString() === "2"
                    ? `${translate("approval_status.rejected")}`
                    : ""}
                </Typography>
              </Label>
            )}
          </>
        )}
      </TableCell>
      <TableCell align="left">
        {loader ? (
          <Skeleton width="100%" />
        ) : (
          <Box sx={{ display: "flex" }}>
            <Tooltip title={translate(`Review Document`)} placement="top" arrow>
              <span>
                <IconButton
                  size="small"
                  disabled={!isReviewDocument || statusDisable}
                  onClick={() => {
                    setFileId(documentId);
                    setDocument({
                      id: id,
                      workflowId: workflowId,
                      documentId: documentId,
                    });
                    handleOpenReviewModal();
                    if (approvalNotRequired === true) {
                      setApprovalNotRequired(true);
                    } else {
                      setApprovalNotRequired(false);
                    }
                  }}
                  sx={{
                    bgcolor: theme.palette.info.light,
                    "&:hover": {
                      bgcolor: alpha(theme.palette.primary.dark, 0.3),
                    },
                  }}
                >
                  <DocumentProcessingIcon
                    height={18}
                    width={18}
                    customColor={
                      !isReviewDocument || statusDisable
                        ? theme.palette.action.disabled
                        : theme.palette.primary.main
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
            {!approvalNotRequired && (
              <>
                <Button
                  variant="contained"
                  size="small"
                  disabled={statusDisable || !writeWorkflowApproval}
                  sx={{
                    ml: 1,
                    height: 22,
                    bgcolor: "secondary.main",
                    fontSize: 10,
                  }}
                  onClick={() => {
                    handleClickOpen("approve", id, workflowId, documentId);
                  }}
                >
                  {translate("Approve")}
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={statusDisable || !writeWorkflowApproval}
                  sx={{
                    ml: 1,
                    height: 22,
                    fontSize: 10,
                  }}
                  onClick={() => {
                    handleClickOpen("reject", id, workflowId, documentId);
                  }}
                >
                  {translate("Reject")}
                </Button>
              </>
            )}
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}
