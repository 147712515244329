import { useRef } from "react";
import {
  createLabelsBoundingBoxes,
  createOcrBoundingBoxes,
} from "../Component/dataView/BoundingBox";

const useAreaMap = ({ values, currentPage, data, height, width, labels }) => {
  const MAP = { name: "my-map", areas: [] as any[] };
  let areas: any[] = [];
  values.fields.forEach((l: any, index: number) => {
    if (
      (l.pageNumber === currentPage ||
        l.pageNumber == 0 ||
        l.pageNumber == null) &&
      l.itemsFields == null
    ) {
      let box = createLabelsBoundingBoxes(
        l,
        index,
        height,
        width,
        data?.["height"],
        data?.["width"],
        labels
      );
      box.key = `fields.${index}`;
      box.id = `fields.${index}`;
      // l.id = `_fields.${index}`
      l.key = `fields.${index}`;
      l.category = "general";
      box.category = "general";
      areas.push(box);
    }
    if (l.itemsFields?.length > 0) {
      l.itemsFields?.forEach((item, idx) => {
        item?.item?.forEach((i: any, ind: number) => {
          if (
            i.pageNumber == currentPage ||
            i.pageNumber == 0 ||
            i.pageNumber == null
          ) {
            let box = createLabelsBoundingBoxes(
              i,
              index,
              height,
              width,
              data?.["height"],
              data?.["width"],
              labels
            );
            box.key = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            i.key = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            box.id = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            i.category = l.name;
            // i.id= `fields.${index}.itemsFields.${idx}.item.${ind}`
            box.category = l.name;
            l.category = l.name;
            areas.push(box);
          }
        });
        l.key = `fields.${index}`;
      });
    }
  });
  data?.lines?.forEach((l: any, index: number) => {
    let box = createOcrBoundingBoxes(
      l,
      index,
      height,
      width,
      data["height"],
      data["width"]
    );
    box.pageNumber = currentPage;
    areas.push(box);
  });
  MAP.areas = areas;
  return {
    MAP: MAP,
  };
};
export default useAreaMap;

export const generateAreaMap = (
  values,
  currentPage,
  data,
  height,
  width,
  labels
) => {
  const MAP = { name: "my-map", areas: [] as any[] };
  let areas: any[] = [];
  values.fields.forEach((l: any, index: number) => {
    if (
      (l.pageNumber === currentPage ||
        l.pageNumber == 0 ||
        l.pageNumber == null) &&
      l.itemsFields == null
    ) {
      let box = createLabelsBoundingBoxes(
        l,
        index,
        height,
        width,
        data?.["height"],
        data?.["width"],
        labels
      );
      box.key = `fields.${index}`;
      box.id = `fields.${index}`;
      // l.id = `_fields.${index}`
      l.key = `fields.${index}`;
      l.category = "general";
      box.category = "general";
      areas.push(box);
    }
    if (l.itemsFields?.length > 0) {
      l.itemsFields?.forEach((item, idx) => {
        item?.item?.forEach((i: any, ind: number) => {
          if (
            i.pageNumber == currentPage ||
            i.pageNumber == 0 ||
            i.pageNumber == null
          ) {
            let box = createLabelsBoundingBoxes(
              i,
              index,
              height,
              width,
              data?.["height"],
              data?.["width"],
              labels
            );
            box.key = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            i.key = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            box.id = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            i.category = l.name;
            // i.id= `fields.${index}.itemsFields.${idx}.item.${ind}`
            box.category = l.name;
            l.category = l.name;
            areas.push(box);
          }
        });
        l.key = `fields.${index}`;
      });
    }
  });
  data?.lines?.forEach((l: any, index: number) => {
    let box = createOcrBoundingBoxes(
      l,
      index,
      height,
      width,
      data["height"],
      data["width"]
    );
    box.pageNumber = currentPage;
    areas.push(box);
  });
  MAP.areas = areas;
  return {
    MAP: MAP,
  };
};
