import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";

const usePermissions = () => {
  let permissions = useSelector(
    (state: any) => state.credential.userTokenDecode.Permissions
  );
  const navigate = useNavigate();
  let permissionsArr: any[] = [];
  // const { logout } = useAuth();
  // const handleLogout = async () => {
  //   try {
  //     await logout();
  //     shutDownIntercomm();
  //     localStorage.removeItem("customLogo");
  //     // navigate(PATH_AUTH.login, { replace: true });
  //     localStorage.removeItem("company_id");
  //   } catch (error) {
  //     console.error(error);
  //     // enqueueSnackbar("Unable to logout!", { variant: "error" });
  //   }
  // };
  // useEffect(() => {
  try {
    if (permissions) {
      permissionsArr = permissions.split(",");
    } else {
      // handleLogout();
      // navigate(PATH_AUTH.login);
    }
  } catch (error) {
    console.log("error", error);
    // store.dispatch(LogoutCred());
  }
  // }, [permissions]);

  const permissionHandle = (permission: string[]) => {
    let checkPermissions = permission.some((r) => permissionsArr.includes(r));
    return checkPermissions;
  };

  return {
    permissionHandle,
  };
};
export default usePermissions;
