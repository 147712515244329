import { TableCell, TableRow, Typography } from "@mui/material";
export default function TableValues(props: { row: any; header: Array<any> }) {
  const { row } = props;
  return (
    <TableRow key={row.line + "_"}>
      <TableCell component="th" scope="row">
        {row?.line}
      </TableCell>
      {props.header?.map((k) => {
        return (
          <>
            <TableCell
              key={row?.line + "_" + k}
              align="left"
              sx={{ maxWidth: 200, minWidth: 150 }}
            >
              <Typography
                sx={{
                  fontSize: 11,
                  fontWeight: "light",
                }}
              >
                {row?.[k]?.value}
              </Typography>
            </TableCell>
          </>
        );
      })}
    </TableRow>
  );
}
