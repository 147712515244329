import { useContext, useReducer, useState } from "react";
import { ItemTable } from "../types/review";
import { ReviewContext } from "../context/ReviewContext";
import { regenerateFieldKeys, regenerateKeys } from "./useLabels";
import { Field, Item, ItemsField } from "../types/reviewResponse";
import { FieldProps } from "../types/types";

export type FormState = {
  field: ItemTable;
  headings: string[];
  rows: any[];
  page: number;
  recordField: Field[];
};
const useItemsTable = ({ values }) => {
  const [open, setOpen] = useState(false);
  const [itemsListKey, setItemsListKey] = useState("");
  const reviewCon = useContext(ReviewContext);
  const { documentFieldsExpectedValues } = reviewCon.expectedValues;
  const { labelColorMap } = reviewCon.label;
  const initialFormState: FormState = {
    rows: [],
    headings: [],
    page: 0,
    field: {
      id: 0,
      uId: "",
      name: "",
      value: "",
      processedValue: "",
      confidence: 0,
      pageNumber: 0,
      isMetaDataValue: false,
      isFieldReviewRequired: false,
      isMandatory: false,
      isExtractedThroughGpt: false,
      boundingPolygon: {},
      expectedValues: {},
      isMultiSelect: false,
      tableDefaultProperties: [],
      tableDefaultPropertiesExpectedValues: [],
      itemsFields: [],
      polygonStatus: null,
    },
    recordField: [],
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_FIELD":
        return {
          ...state,
          field: action.payload,
        };
      case "SET_HEADINGS":
        let headings_: any = [];
        if (action.payload?.itemsFields?.length > 0) {
          action.payload?.itemsFields?.[0]?.item?.forEach((item: any) => {
            headings_.push(item.name);
          });
        } else {
          action.payload?.tableDefaultProperties?.forEach((item: string) => {
            headings_.push(item);
          });
        }
        return {
          ...state,
          headings: headings_,
        };
      case "SET_ROW":
        let { row: update_row, k, i } = action.payload;
        update_row[k].value = action.payload.inputValue;
        let keys = update_row[k]?.key.split(".");
        let update_fields = state.field;
        update_fields.itemsFields[keys[3]].item[keys[5]].value =
          action.payload.inputValue;
        let newRows = state.rows;
        newRows[i] = update_row;
        return {
          ...state,
          field: update_fields,
          rows: newRows,
        };
      case "SET_ROWS":
        let rows_: any[] = [];
        state.field.itemsFields?.forEach((itemField: any, index: number) => {
          let row = {};
          itemField?.item?.forEach((item: any) => {
            row[item.name] = {
              value: item.value,
              key: item.key,
              expectedValues: item.expectedValues,
            };
          });
          rows_.push({ ...row, line: index + 1 });
        });

        return {
          ...state,
          rows: rows_,
        };
      case "NEW_ROW":
        let table = structuredClone(state.field?.itemsFields);
        let newIndex = table?.length;
        let newLabels: any[] = [];
        if (table?.length > 0) {
          table?.[0]?.item.forEach((l: any, ind: number) => {
            let item = {
              name: l.name,
              value: "",
              key: `${itemsListKey}.itemsFields.${newIndex}.item.${ind}`,
              confidence: 0,
              isFieldReviewRequired: false,
              isMandatory: false,
              isMetaDataValue: false,
              pageNumber: l.pageNumber,
              boundingPolygon: null,
              fillColor: l.fillColor,
              strokeColor: l.strokeColor,
              preFillColor: l.preFillColor,
              category: state.field.name,
              expectedValues: l.expectedValues,
            };
            newLabels.push(item);
          });
        } else if (state.field?.tableDefaultProperties?.length > 0) {
          state.field?.tableDefaultProperties?.forEach(
            (l: string, ind: number) => {
              let item = {
                name: l,
                value: "",
                key: `${itemsListKey}.itemsFields.${newIndex}.item.${ind}`,
                confidence: 0,
                isFieldReviewRequired: false,
                isMandatory: false,
                isMetaDataValue: false,
                pageNumber: 0,
                boundingPolygon: null,
                fillColor: "light-gray",
                strokeColor: labelColorMap[l],
                preFillColor: labelColorMap[l] + "60",
                category: state.field.name,
                expectedValues:
                  documentFieldsExpectedValues?.find((item) => item.field === l)
                    ?.expectedValues ??
                  state?.field?.tableDefaultPropertiesExpectedValues?.find(
                    (item) => item.fieldName === l
                  )?.expectedValues ??
                  [],
              };
              newLabels.push(item);
            }
          );
        }
        let row = {};
        newLabels?.forEach((item: any) => {
          row[item.name] = {
            value: item.value,
            key: item.key,
            expectedValues: item.expectedValues,
          };
        });
        let rows = structuredClone(state.rows);
        rows = [...rows, { ...row, line: rows.length + 1 }];
        let maxItemIndex = state.field.maxItemIndex + 1;
        table = [
          ...table,
          {
            item: newLabels,
            uId: "",
            index: maxItemIndex,
            isRemoved: false,
            isNew: true,
          },
        ];
        let page_ = state.page;
        if (rows.length > 10) {
          page_ = Math.ceil(rows.length / 10) - 1;
        }
        let newField: Field = {
          name: state.field.name,
          value: state.field.value,
          pageNumber: state.field.pageNumber,
          confidence: 0.9,
          isRemoved: false,
          isNew: false,
          boundingPolygon: null,
          itemsFields: [
            {
              index: maxItemIndex,
              isRemoved: false,
              isNew: true,
              item: newLabels?.map((item: any) => {
                return {
                  name: item.name,
                  value: item.value,
                  boundingPolygon: item.boundingPolygon,
                  pageNumber: item.pageNumber,
                  confidence: 0.9,
                  isRemoved: false,
                  isNew: true,
                };
              }),
            },
          ],
        };
        let recordField = updatePayloadFields([newField], state.recordField);
        return {
          ...state,
          rows: rows,
          page: page_,
          field: { ...state.field, itemsFields: table, maxItemIndex },
          recordField: [...recordField],
        };
      case "DELETE_COLUMN":
        if (action.payload.enableDeleteTable) {
          return {
            ...state,
            headings: [],
            rows: [],
            field: { ...state.field, itemsFields: [] },
          };
        }
        const deleteTable = (state.field?.itemsFields || []).map((item) => {
          if (item?.item) {
            return {
              ...item,
              item: item.item.filter(
                (item_) => item_.name !== action.payload.name
              ),
            };
          }
          return item;
        });
        const del_headings = (state.headings || []).filter(
          (heading: string) => heading !== action.payload.name
        );
        let new_columns_rows: any[] = [];
        let deleted_cols_with_new_keys = regenerateFieldKeys(
          {
            ...state.field,
            itemsFields: deleteTable,
          },
          state.field.key?.split(".")?.[1]
        );
        deleted_cols_with_new_keys?.itemsFields?.forEach(
          (itemField: any, index: number) => {
            let row = {};
            itemField?.item?.forEach((item: any) => {
              row[item.name] = {
                value: item.value,
                key: item.key,
                expectedValues: item.expectedValues,
              };
            });
            new_columns_rows.push({ ...row, line: index + 1 });
          }
        );

        return {
          ...state,
          headings: del_headings,
          rows: new_columns_rows,
          field: { ...state.field, ...deleted_cols_with_new_keys },
        };
      case "DELETE_ROW":
        let deleted_rows = state.field.itemsFields?.filter(
          (row: any, i: number) => i !== action.payload
        );
        let field = {
          ...state.field,
          itemsFields: deleted_rows,
        };
        let deleted_rows_with_new_keys: FieldProps = regenerateFieldKeys(
          field as FieldProps,
          field.key?.split(".")?.[1]
        );
        let new_rows: any[] = [];
        if (!Array.isArray(deleted_rows_with_new_keys)) {
          deleted_rows_with_new_keys?.itemsFields?.forEach(
            (itemField: any, index: number) => {
              let row = {};
              itemField?.item?.forEach((item: any) => {
                row[item.name] = {
                  value: item.value,
                  key: item.key,
                  expectedValues: item.expectedValues,
                };
              });
              new_rows.push({ ...row, line: index + 1 });
            }
          );
        }

        return {
          ...state,
          rows: new_rows,
          field: {
            ...state.field,
            itemsFields: deleted_rows_with_new_keys?.itemsFields,
          },
        };
      case "CHANGE_PAGE":
        return {
          ...state,
          page: action.payload,
        };
      case "RESET_STATE":
        return {
          ...state,
          headings: [],
          rows: [],
          page: 0,
          field: { ...state.field, itemsFields: [] },
          recordField: [],
        };
      case "ADD_FIELD":
        let fields = updatePayloadFields(action.payload, state.recordField);
        return {
          ...state,
          recordField: fields,
        };
      case "REMOVE_FIELD":
        let fields_ = updatePayloadFields(action.payload, state.recordField);
        return {
          ...state,
          recordField: fields_,
        };
      case "EDIT_FIELD":
        let key = action.payload.key;
        let keys_ = key?.split(".");
        let field_ = state.field.itemsFields[keys_[3]];
        let index = field_.index;
        let items: Item[] = [];
        field_.item.forEach((item: Item) => {
          let item_: Item = {
            boundingPolygon: item.boundingPolygon,
            confidence: item.confidence,
            isRemoved: item.isRemoved || false,
            isNew: false,
            name: item.name,
            value: item.value,
            pageNumber: item.pageNumber,
          };
          if (item.name === action.payload.name) {
            item.value = action.payload.value;
          }
          items.push(item_);
        });
        let editField: Field = {
          name: state.field.name,
          value: null,
          pageNumber: state?.field.pageNumber,
          confidence: null,
          isRemoved: false,
          isNew: false,
          itemsFields: [
            {
              index: index,
              isRemoved: false,
              isNew: false,
              item: items,
            },
          ],
          boundingPolygon: null,
        };
        let recordfields = updatePayloadFields([editField], state.recordField);
        return {
          ...state,
          recordField: recordfields,
        };
      default:
        return state;
    }
  };

  const [formState, dispatch] = useReducer(reducer, initialFormState);
  const handleOpenItemsTable = (key: string) => {
    let [k1, k2] = key?.split(".");
    setItemsListKey(key);
    dispatch({
      type: "SET_FIELD",
      payload: structuredClone(values?.[k1]?.[k2]),
    });
    dispatch({
      type: "SET_HEADINGS",
      payload: structuredClone(values?.[k1]?.[k2]),
    });
    dispatch({
      type: "SET_ROWS",
      payload: structuredClone(values?.[k1]?.[k2]?.itemsFields),
    });
    setOpen(true);
  };
  const handleCloseItemsTable = () => {
    setItemsListKey("");
    setOpen(false);
  };

  return {
    open,
    handleCloseItemsTable,
    handleOpenItemsTable,
    itemsListKey,
    formState,
    dispatch,
  };
};

export default useItemsTable;

export const updatePayloadFields = (payload: Field[], fields_: Field[]) => {
  let fields = fields_;
  payload?.forEach((field: any) => {
    if (field.name !== "un_labelled") {
      let name = field.name;
      let index = fields.findIndex((field_: Field) => field_.name === name);
      if (index !== -1) {
        if (field.itemsFields?.length > 0) {
          field.itemsFields.forEach((itemField: ItemsField) => {
            let rowIndex = fields[index].itemsFields.findIndex(
              (item: ItemsField) => {
                return item.index === itemField.index;
              }
            );
            if (field.isRemoved) {
              fields[index].isRemoved = true;
            }
            if (rowIndex !== -1) {
              if (itemField.item.length > 0) {
                itemField.item.forEach((itemRow: Item) => {
                  let colIndex = fields[index].itemsFields[
                    rowIndex
                  ]?.item?.findIndex((item: Item) => {
                    return item.name === itemRow.name;
                  });
                  if (fields[index].itemsFields[rowIndex].isNew) {
                    itemField.isNew = true;
                  }
                  if (itemField.isRemoved) {
                    fields[index].itemsFields[rowIndex].isRemoved = true;
                  }
                  if (colIndex !== -1) {
                    if (
                      fields[index].itemsFields[rowIndex].item[colIndex].isNew
                    ) {
                      itemRow.isNew = true;
                    }
                    fields[index].itemsFields[rowIndex].item[colIndex] =
                      itemRow;
                  } else {
                    fields[index].itemsFields[rowIndex].item.push(itemRow);
                  }
                });
              }
            } else {
              fields[index].itemsFields.push(itemField);
            }
          });
        } else {
          fields[index] = field;
        }
      } else {
        fields.push(field);
      }
    }
  });
  return fields;
};
