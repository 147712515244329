import mixpanel from "mixpanel-browser";
//settings.js
import { ENABLE_SEGMENT, MIXPANEL, SEGMENT } from "src/constants/settings";
import { store } from "src/redux/store";
import { AnalyticsBrowser } from "@segment/analytics-next";

const analytics = ENABLE_SEGMENT
  ? AnalyticsBrowser.load({
      writeKey: SEGMENT.writeKey,
    })
  : {
      track: async () => {},
      identify: async (_, callBackOrProps = () => {}) => {
        return typeof callBackOrProps == "function"
          ? callBackOrProps?.()
          : callBackOrProps;
      },
      page: async () => {},
      ready: async () => {},
      user: async () => {
        return { id: () => {}, traits: () => {} };
      },
      group: async () => {},
      people: async () => {},
      reset: async () => {},
      alias: async () => {},
    };

mixpanel.init(MIXPANEL.id, { track_pageview: true });

const plan = () => {
  const { plan, addOns } = store.getState()?.organization?.org;
  let checkPlanType = plan == "basic" ? "Free" : "Paid";
  let obj = { checkPlanType, AddOns: addOns };
  return obj;
};

let env_check = MIXPANEL.connection;

let actions = {
  console: () => {
    analytics.ready(async function () {
      var user = await analytics.user();
      var id = user.id();
      var traits = user.traits();
    });
  },
  identify: (id, check = true, callback = () => {}) => {
    if (env_check) {
      mixpanel.identify(id);
      if (check) {
        analytics.identify(id, () => {
          callback();
        });
      }
    }
  },
  alias: (id) => {
    if (env_check) {
      mixpanel.alias(id);
      analytics.alias(id);
    }
  },
  track: (name, props) => {
    let prop = props;
    let Addons = plan()?.AddOns?.length ? plan().AddOns : "None";
    Object.assign(props, { Plan: plan()?.checkPlanType, Addons });
    if (env_check) {
      mixpanel.track(name, prop);
      analytics.track(name, prop);
    }
  },
  people: {
    set: async (props) => {
      if (env_check) {
        mixpanel.people.set(props);
        var user = await analytics.user();
        var id = user.id();
        var traits = user.traits();
        analytics.identify(id, { ...props });
      }
    },
    union: async (typesOfDoc, props) => {
      if (env_check) {
        mixpanel.people.union(typesOfDoc, props);
        var user = await analytics.user();
        var id = user.id();
        var traits = user.traits();
        let uniqueProps = [...new Set([...traits?.[typesOfDoc], props])];
        analytics.identify(id, { [typesOfDoc]: uniqueProps });
      }
    },
    set_once: (typesOfDoc, props) => {
      if (env_check) {
        mixpanel.people.set_once(typesOfDoc, props);
      }
    },
    reset: () => {
      if (env_check) {
        analytics.user().traits({});
      }
    },
  },
  group: {
    set_group: (props) => {
      if (env_check) {
        mixpanel.set_group("company_id", props);
        analytics.group(props);
      }
    },
    set_property: (orgId, props) => {
      if (env_check) {
        mixpanel.get_group("company_id", orgId).set(props);
        analytics.group(orgId, props);
      }
    },
    union: (orgId, prop_name, prop_value) => {
      if (env_check) {
        mixpanel.get_group("company_id", orgId).union(prop_name, [prop_value]);
        let user = analytics.group();
        let uniqueProps = [
          ...new Set([...user.traits()[prop_name], prop_value]),
        ];
        analytics.group(orgId, { [prop_name]: uniqueProps });
      }
    },
    reset: () => {
      if (env_check) {
        analytics.group().traits({});
      }
    },
  },
  register: (props) => {
    if (env_check) {
      mixpanel.register(props);
    }
  },
};

export let Mixpanel = actions;
