import { Dialog, IconButton, useTheme, Button } from "@mui/material";
import { TableCell, TableRow, Typography } from "@mui/material";
import { DialogContent, DialogActions } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import useLocales from "src/hooks/useLocales";
import DeleteIcon from "../../../../../assets/icon/delete";
import AlertIllustration from "src/assets/illustrations/infoalertIllustrations";
import { Box } from "@mui/material";
import CloseButton from "src/components/CloseButton";
import { NewTextFormField } from "src/components/textField";
import EditIcon from "src/assets/icon/edit";
import MyDenseAutocomplete from "src/components/denseForm/Autocomplete";
import MyMenuItem from "src/components/denseForm/MenuItem";
import { ExpectedValues } from "src/pages/formvalidation/types/reviewContext";
import { ReviewContext } from "src/pages/formvalidation/context/ReviewContext";

export default function TableValues(props: {
  row: any;
  header: Array<any>;
  deleteRow: any;
  i: number;
  rows: any;
  dispatch: any;
  length: number;
  page: number;
  enableDeleteColumn?: boolean;
}) {
  const { row, i, rows, dispatch, enableDeleteColumn } = props;
  const { translate } = useLocales();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    setOpen(true);
  };

  const theme = useTheme();
  const reviewContext = useContext(ReviewContext);
  const { expectedValues } = reviewContext;
  const { documentFieldsExpectedValues } = expectedValues;

  return (
    <TableRow
      onDoubleClick={() => {
        setEdit(!edit);
      }}
      key={i + 1 + "_"}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <CloseButton handleClose={handleClose} />
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <AlertIllustration />
          </Box>
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ fontSize: "13px", color: "text.greyLight" }}
          >
            {translate("Are you sure you want to delete this row?")}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            pt: 1,
          }}
        >
          <Button size="small" variant="outlined" onClick={handleClose}>
            {translate("Cancel")}
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              props.deleteRow(i + props.page);
              handleClose();
            }}
            autoFocus
          >
            {translate("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <TableCell
        sx={{
          fontSize: 10,
          fontWeight: "light",
        }}
      >
        {props.page + i + 1}
      </TableCell>
      {props.header?.map((k, idx) => {
        let expectedValuesOption =
          documentFieldsExpectedValues?.find((item) => item.field === k)
            ?.expectedValues ?? [];
        let displayValue = "";
        if (
          Array.isArray(expectedValuesOption) &&
          expectedValuesOption.length > 0
        ) {
          displayValue =
            expectedValuesOption?.find((item) => item.value === row[k]?.value)
              ?.description ?? "";
        } else {
          displayValue = row[k]?.value;
        }
        return (
          <Fragment key={"cell-" + k + "-" + idx}>
            <TableCell
              key={row?.line + "_" + k}
              align="left"
              sx={{
                maxWidth: 200,
                minWidth: 150,
              }}
            >
              {" "}
              {edit ? (
                <EditValue
                  {...{
                    value: row[k]?.value,
                    row,
                    k,
                    idx,
                    dispatch,
                    rows,
                    i,
                    expectedValuesOption,
                  }}
                />
              ) : (
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: "light",
                  }}
                >
                  {displayValue}
                </Typography>
              )}
            </TableCell>
            {enableDeleteColumn && <TableCell></TableCell>}
          </Fragment>
        );
      })}
      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          {!edit ? (
            <IconButton
              aria-label="edit"
              onClick={() => {
                setEdit(true);
              }}
              size="small"
            >
              <EditIcon
                height={20}
                width={20}
                customColor={theme.palette.action.active}
              />
            </IconButton>
          ) : (
            <></>
          )}
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete()}
            size="small"
          >
            <DeleteIcon
              height={20}
              width={20}
              customColor={theme.palette.action.active}
            />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

const EditValue = ({ value, row, k, dispatch, i, expectedValuesOption }) => {
  const [inputValue, setInputValue] = useState(value);
  const { translate } = useLocales();
  const [selectOption, setSelectOption] = useState({
    label:
      expectedValuesOption?.find((item) => item.value === value)?.description ??
      "",
    value: value,
  });
  const handleBlur = () => {
    dispatch({
      type: "SET_ROW",
      payload: {
        row,
        k,
        i,
        inputValue,
      },
    });
    dispatch({
      type: "EDIT_FIELD",
      payload: {
        key: row[k].key,
        name: k,
        value: inputValue,
      },
    });
  };

  if (Array.isArray(expectedValuesOption) && expectedValuesOption.length > 0) {
    return (
      <MyDenseAutocomplete
        value={selectOption}
        denser
        disableClearable
        onBlur={handleBlur}
        onChange={(event, newValue) => {
          if (newValue != null && typeof newValue !== "string") {
            setInputValue(newValue.value);
            setSelectOption({
              label: newValue.label,
              value: newValue.value,
            });
          }
        }}
        renderOption={(props: any, option: any, state: object) => (
          <MyMenuItem {...{ option, displayKey: "label", ...props }} />
        )}
        options={
          expectedValuesOption?.map((item: ExpectedValues) => {
            return {
              label: translate(item.description),
              value: item.value,
            };
          }) ?? []
        }
      />
    );
  }
  return (
    <NewTextFormField
      value={inputValue}
      multiline
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onBlur={handleBlur}
    />
  );
};
