import { useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { store } from "src/redux/store";
import { PostAsync } from "src/common/httpRequests";
import { styled } from "@mui/material/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
import { MixpanelFunction } from "src/mixpanel/mixpanelFunction";
import { PATH_DASHBOARD } from "src/routes/paths";
import Page from "src/components/Page";
import Logo from "src/components/Logo";
import { Box, Stack, Alert, Container, Typography } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import { AlertTitle } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { SectionStyle } from "../auth/Login";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 460,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const Impersonate = () => {
  const email = store.getState()?.credential?.userTokenDecode.email;
  const { LoginImpersonate } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { translate } = useLocales();

  const mdUp = useResponsive("up", "md");
  const [errorMsg, setErrorMsg] = useState<any>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const impersonateToken = searchParams?.get("token");
    const payload = { token: `${impersonateToken}` };

    if (email) {
      setErrorMsg("impersonate_error");
    } else {
      if (impersonateToken) {
        const loginAsImpersonate = async () => {
          const response = await PostAsync(
            `${API_GATEWAY}/api/userservice/Impersonate`,
            payload
          );

          try {
            if (response.status === 200) {
              setIsLoggedIn(true);
              await LoginImpersonate(response?.data);
              MixpanelFunction({
                eventName: "Login",
                eventSource: "Authentication",
                user: "Login",
                userEmail: response?.data?.profile?.email,
                status: "successful",
                signInMethod: "Impersonate Login",
                organizationId: response?.data?.profile?.organizationId,
                userProfile: response?.data?.profile,
                impersonateMode: true,
              });
              navigate(PATH_DASHBOARD.general.app);
            } else if (response.status === 500) {
              setErrorMsg(`${response.data.Detail}`);
            } else {
              setErrorMsg(`${response.data.Detail}`);
            }
          } catch (err) {
            setErrorMsg(`${err.data.Detail}`);
          }
        };
        loginAsImpersonate();
      } else {
        setErrorMsg(`token_not_found`);
      }
    }
  }, []);

  return (
    <Page title="Impersonate">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && <SectionStyle />}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="top" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate("signIn_impersonate")}
                </Typography>
                {isLoggedIn && (
                  <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    <CircularProgress color="success" />
                    Logging in<strong>Please wait!</strong>
                  </Alert>
                )}
                {errorMsg && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {translate(errorMsg)}
                  </Alert>
                )}
              </Box>
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
};

export default Impersonate;
